import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import Cookies from 'js-cookie';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import loginImage from '../../assets/images/login.svg';
import PopUpAlert from '../../common/Alerts/PopUpAlert';
import { Loader } from '../../common/Loader';
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb';
import useApiCalls from '../../utility/API/APICalls';
import { useLogin } from '../../utility/Auth/Auth';

const Login = () => {
  const navigate = useNavigate();
  const { loginUser } = useLogin();
  const apicalls = useApiCalls();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [popupHandling, setPopUpHandling] = useState({
    title: 'Login Error',
    message: '',
    okayText: 'Okay',
    okayColor: 'red-600',
  });

  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      navigate('/dashboard');
    }
  }, [navigate]);

  const callApi = async () => {
    setIsLoading(true);
    const payload = { username: email, password: password };
    // console.log('payload',payload)
    try {
      const response = await apicalls('api/login', 'POST', payload);
      const respData = response.data;
      if (response.status === 201 || response.status === 200) {
        if (respData.data?.session?.token) {
          loginUser(respData.data?.session?.token, respData.data.user);
        } else {
          setPopUpHandling((prev) => ({ ...prev, message: respData.msg }));
          setIsVisible(true);
        }
      } else {
        throw new Error(respData.msg);
      }
    } catch (error) {
      setPopUpHandling((prev) => ({ ...prev, message: error.message }));
      setIsVisible(true);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUserInfo = async (accessToken) => {
    // console.log('accessToken',accessToken)
    const payloady = {
      id_token: accessToken,
      user_type: 'Company',
    };
    setIsLoading(true);
    try {
      const response = await apicalls('api/googlelogin', 'POST', payloady);
      const respData = response.data;
      if (response.status === 201 || response.status === 200) {
        if (respData.data?.session?.token) {
          loginUser(respData.data?.session?.token, respData.data.user);
        } else {
          setPopUpHandling((prev) => ({ ...prev, message: respData.msg }));
          setIsVisible(true);
        }
      } else {
        throw new Error(respData.msg);
      }
    } catch (error) {
      setPopUpHandling((prev) => ({ ...prev, message: error.message }));
      setIsVisible(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFormfields = (type, val) => {
    if (val) {
      setMessage('');
    }
    if (type === 'email') {
      setEmail(val);
    }
    if (type === 'password') {
      setPassword(val);
    }
  };

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        await fetchUserInfo(tokenResponse.access_token);
      } catch (error) {}
    },
    onError: (error) => {},
  });

  const signInWithGoogle = () => {
    googleLogout();
    login();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      setMessage('Please fill in Email Address');
      return;
    }
    if (!password) {
      setMessage('Please fill in your Password');
      return;
    }

    callApi();
  };

  return (
    <div className="flex flex-col items-center justify-center space-y-2 h-1/2 p-5 rounded-lg">
      <div className="mt-10">
        <Breadcrumb show={false} pageName="Sign In / Sign up" className="" />
        <h1 className="text-4xl font-semibold py-5">Login</h1>
      </div>

      <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark  w-full lg:w-2/3 h-1/2">
        <div className="flex flex-col xl:flex-row items-center">
          <div className="w-full xl:w-1/2 hidden xl:block">
            <div className="py-10 px-8 text-center">
              <Link className="mb-5 inline-block" to="/">
                <img
                  className="hidden dark:block"
                  src={require('../../assets/images/logo.png')}
                  alt="Logo"
                />
                {/* <img className="dark:hidden" src={LogoDark} alt="Logo" /> */}
              </Link>

              <p
                className="text-lg xl:text-xl font-semibold"
                style={{ fontFamily: 'sans-serif' }}
              >
                Business growth starts here
              </p>

              <span className="mt-8 inline-block">
                <img src={loginImage} alt="signup" className="w-full" />
              </span>
            </div>
          </div>

          <div className="w-full xl:w-1/2 border-t xl:border-t-0 xl:border-l border-stroke dark:border-strokedark p-6 sm:p-12">
            {isLoading && <Loader />}
            {isVisible && (
              <PopUpAlert
                title={popupHandling.title}
                message={popupHandling.message}
                setIsVisible={setIsVisible}
                okayText={popupHandling.okayText}
                okayColor={popupHandling.okayColor}
                setClickOkay={() => setIsVisible(false)}
              />
            )}

            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="mb-2 block font-medium text-black dark:text-white">
                  Email
                </label>
                <div className="relative">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => handleFormfields('email', e.target.value)}
                    placeholder="Enter your email"
                    className="w-full rounded-lg border border-stroke bg-transparent py-3 pl-4 pr-10 text-black outline-none focus:border-primary dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                  />
                  <span className="absolute right-4 top-3">
                    {/* SVG icon */}
                  </span>
                </div>
              </div>

              <div className="mb-4">
                <label className="mb-2 block font-medium text-black dark:text-white">
                  Password
                </label>
                <div className="relative">
                  <input
                    type="password"
                    value={password}
                    onChange={(e) =>
                      handleFormfields('password', e.target.value)
                    }
                    placeholder="Your password"
                    className="w-full rounded-lg border border-stroke bg-transparent py-3 pl-4 pr-10 text-black outline-none focus:border-primary dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                  />
                  <span className="absolute right-4 top-3">
                    {/* SVG icon */}
                  </span>
                </div>
              </div>

              {message && (
                <div className="mb-4 bg-danger bg-opacity-30 rounded-md px-4 py-2">
                  <label className="text-sm text-danger">{message}</label>
                </div>
              )}

              <div className="mb-4">
                <input
                  type="submit"
                  value="Sign In"
                  className="w-full cursor-pointer rounded-lg border border-primary bg-primary py-3 text-white transition hover:bg-opacity-90"
                />
              </div>
            </form>

            <button
              className="flex w-full items-center justify-center font-medium gap-3 rounded-lg border border-stroke bg-gray py-3 hover:bg-opacity-50 dark:border-strokedark dark:bg-meta-4 dark:hover:bg-opacity-50"
              onClick={() => signInWithGoogle()}
            >
              <span>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_191_13499)">
                    <path
                      d="M19.999 10.2217C20.0111 9.53428 19.9387 8.84788 19.7834 8.17737H10.2031V11.8884H15.8266C15.7201 12.5391 15.4804 13.162 15.1219 13.7195C14.7634 14.2771 14.2935 14.7578 13.7405 15.1328L13.7209 15.2571L16.7502 17.5568L16.96 17.5774C18.8873 15.8329 19.9986 13.2661 19.9986 10.2217"
                      fill="#4285F4"
                    />
                    <path
                      d="M10.2055 19.9999C12.9605 19.9999 15.2734 19.111 16.9629 17.5777L13.7429 15.1331C12.8813 15.7221 11.7248 16.1333 10.2055 16.1333C8.91513 16.1259 7.65991 15.7205 6.61791 14.9745C5.57592 14.2286 4.80007 13.1801 4.40044 11.9777L4.28085 11.9877L1.13101 14.3765L1.08984 14.4887C1.93817 16.1456 3.24007 17.5386 4.84997 18.5118C6.45987 19.4851 8.31429 20.0004 10.2059 19.9999"
                      fill="#34A853"
                    />
                    <path
                      d="M4.39899 11.9777C4.1758 11.3411 4.06063 10.673 4.05807 9.99996C4.06218 9.32799 4.1731 8.66075 4.38684 8.02225L4.38115 7.88968L1.19269 5.4624L1.0884 5.51101C0.372763 6.90343 0 8.4408 0 9.99987C0 11.5589 0.372763 13.0963 1.0884 14.4887L4.39899 11.9777Z"
                      fill="#FBBC05"
                    />
                    <path
                      d="M10.2059 3.86663C11.668 3.84438 13.0822 4.37803 14.1515 5.35558L17.0313 2.59996C15.1843 0.901848 12.7383 -0.0298855 10.2059 -3.6784e-05C8.31431 -0.000477834 6.4599 0.514732 4.85001 1.48798C3.24011 2.46124 1.9382 3.85416 1.08984 5.51101L4.38946 8.02225C4.79303 6.82005 5.57145 5.77231 6.61498 5.02675C7.65851 4.28118 8.9145 3.87541 10.2059 3.86663Z"
                      fill="#EB4335"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_191_13499">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              Sign in with Google
            </button>
            <div className="self-center my-5 flex flex-row justify-end items-right">
              <h1>Don't have an account yet ? </h1>
              <Link to="/signup" className="text-primary font-medium ml-2">
                Sign up
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
