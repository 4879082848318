import React from 'react';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumbs/Breadcrumb';
import DefaultLayout from '../layout/DefaultLayout';

const Settings = () => {
  const navigate = useNavigate();

  const reDirecton = (reDirectTo) => {
    navigate(reDirectTo);
  };

  return (
    <DefaultLayout>
      <Breadcrumb pageName="Settings" />
      <div className="p-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        <button
          className="flex bg-slate-300 text-slate-500 hover:text-black w-full h-10 justify-center items-center p-2 text-lg rounded-xl duration-300 hover:scale-110"
          onClick={() => reDirecton('/transactions')}
        >
          Transactions History
        </button>
        <button
          className="flex bg-slate-300 text-slate-500 hover:text-black w-full h-10 justify-center items-center p-2 text-lg rounded-xl duration-300 hover:scale-110"
          onClick={() => reDirecton('/privacy-policy')}
        >
          Privacy Policy
        </button>
        <button
          className="flex bg-slate-300 text-slate-500 hover:text-black w-full h-10 justify-center items-center p-2 text-lg rounded-xl duration-300 hover:scale-110"
          onClick={() => reDirecton('/tnc')}
        >
          Terms and Conditions
        </button>
        <button
          className="flex bg-slate-300 text-slate-500 hover:text-black w-full h-10 justify-center items-center p-2 text-lg rounded-xl duration-300 hover:scale-110"
          onClick={() => reDirecton('/contact')}
        >
          Contact Us
        </button>

        {/* Add more buttons as needed */}
      </div>
    </DefaultLayout>
  );
};

export default Settings;
