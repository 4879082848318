import React, { useEffect, useState, useCallback } from 'react';
import CheckBox from '../../common/CheckBox';
import { Loader } from '../../common/Loader';
import useApiCalls from '../../utility/API/APICalls';

const Gender = React.memo(
  ({ setGender, current_status = 'fresh', current_data = [] }) => {
    const [selectedGenders, setSelectedGenders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [checkBoxData, setCheckBoxData] = useState([]);

    console.log('current_data gender', current_data);
    if (current_status?.trim().toLowerCase() === 'fresh') {
      var editable = true;
    } else if (current_status?.trim().toLowerCase() === 'draft') {
      var editable = true;
    } else {
      var editable = false;
    }

    useEffect(() => {
      if (current_data.length > 0 && checkBoxData.length > 0) {
        const selected = checkBoxData.map((item) =>
          current_data.some((data) => data.id === item.id),
        );
        setSelectedGenders(selected);
        setGender(selected);
      }
    }, [checkBoxData]);

    useEffect(() => {
      const selectedValues = checkBoxData.filter((_, i) => selectedGenders[i]);
      setGender(selectedValues);
    }, [selectedGenders, checkBoxData, setGender]);

    const handleCheckboxChange = (index) => {
      const updatedGenders = [...selectedGenders];
      updatedGenders[index] = !updatedGenders[index];
      setSelectedGenders(updatedGenders);
    };

    const apicalls = useApiCalls();
    const callApi = useCallback(
      async (endpoint) => {
        if (endpoint) {
          setIsLoading(true);
          try {
            const response = await apicalls(endpoint, 'GET');
            if (response.status === 200 && response.data) {
              // console.log('Gender response.data', response.data.data);
              const formattedData = response.data.data.map((item) => ({
                id: item.id,
                value: item.value,
              }));
              setCheckBoxData(formattedData);
              setSelectedGenders(Array(formattedData.length).fill(false)); // Initialize selectedGenders after checkBoxData is populated
            }
          } catch (error) {
            console.error('API Call Error:', error);
          } finally {
            setIsLoading(false);
          }
        } else {
          setCheckBoxData([]);
        }
      },
      [apicalls],
    );

    useEffect(() => {
      if (checkBoxData.length <= 0) {
        callApi('api/gender');
      }
    }, []);

    return (
      <div className="flex flex-col space-y-2 justify-start items-start">
        {isLoading ? (
          <Loader />
        ) : (
          checkBoxData.length > 0 &&
          checkBoxData.map((item, index) => (
            <CheckBox
              key={index}
              title={item.value}
              index={index}
              isChecked={selectedGenders[index]}
              onCheckboxChange={() => handleCheckboxChange(index)}
              disabled={!editable}
            />
          ))
        )}
      </div>
    );
  },
);

export default Gender;
