import React from 'react';

const FifthBlock = () => {
  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="container px-6 py-10 mx-auto">
        <h1 className="text-2xl font-semibold text-center text-gray-800 capitalize lg:text-3xl dark:text-white">
          What our <span className="text-blue-500 ">clients</span> say
        </h1>

        <div className="grid grid-cols-1 gap-8 mx-auto mt-8 lg:grid-cols-2 xl:mt-10 max-w-7xl">
          <div className="p-6 bg-gray-100 rounded-lg dark:bg-gray-800 md:p-8">
            <p className="leading-loose text-gray-500 dark:text-gray-300">
              “If you're looking to advertise, then this is the place. These
              guys have got real brains, I must say. The way these guys
              explained things to me was too good. They will go far ahead”.
            </p>

            <div className="flex items-center mt-6">
              <img
                className="object-cover rounded-full w-14 h-14"
                src={require('../../../assets/images/avatar.png')}
                alt=""
              />

              <div className="mx-4">
                <h1 className="font-semibold text-blue-500"></h1>
                <span className="text-sm text-gray-500 dark:text-gray-300">
                  KFC - Sales Head, Delhi
                </span>
              </div>
            </div>
          </div>

          <div className="p-6 bg-gray-100 rounded-lg dark:bg-gray-800 md:p-8">
            <p className="leading-loose text-gray-500 dark:text-gray-300">
              Professional services, pleasant experience, and very cooperative.
            </p>

            <div className="flex items-center mt-6">
              <img
                className="object-cover rounded-full w-14 h-14"
                src={require('../../../assets/images/avatar.png')}
                alt=""
              />

              <div className="mx-4">
                <h1 className="font-semibold text-blue-500"></h1>
                <span className="text-sm text-gray-500 dark:text-gray-300">
                  Nyka - Marketing Manager
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FifthBlock;
