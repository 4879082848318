import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import signupImage from '../../assets/images/signup.svg';
import PopUpAlert from '../../common/Alerts/PopUpAlert';
import { Loader } from '../../common/Loader';
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb';
import useApiCalls from '../../utility/API/APICalls';
import { useLogin } from '../../utility/Auth/Auth';

const Signup = () => {
  const navigate = useNavigate();
  const apicalls = useApiCalls();
  const { loginUser } = useLogin();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [otp, setOtp] = useState('');
  const [otpDetailsFromApi, setOtpDetailsFromApi] = useState({});
  const [otpVerified, setOtpVerified] = useState(false);
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [otpState, setOtpState] = useState(0); // const otpStates = ['notSent','Sent' ,'Verified']

  const [popupHandling, setPopUpHandling] = useState({
    title: 'Registration Error',
    message: '',
    okayText: 'Okay',
    okayColor: 'red-600',
  });

  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      navigate('/dashboard');
    }
  }, [navigate]);

  const callApi = async (type, endpoint, payload) => {
    setIsLoading(true);

    // console.log('payload',payload)
    try {
      const response = await apicalls(endpoint, 'POST', payload);
      const respData = response.data;
      if (response.status === 201 || response.status === 200) {
        if (type === 'submit') {
          if (respData.data?.session?.token) {
            loginUser(respData.data?.session?.token, respData.data.user);
          } else {
            setPopUpHandling((prev) => ({ ...prev, message: respData.msg }));
            setIsVisible(true);
          }
        }
        if (type === 'submitEmail') {
          if (respData.data) {
            setOtpDetailsFromApi(respData.data);
            setOtpState(1);
          } else {
            setPopUpHandling((prev) => ({ ...prev, message: respData.msg }));
            setIsVisible(true);
          }
        }
        if (type === 'submitOtp') {
          if (respData.data) {
            setOtpVerified(true);
            setOtpState(2);
          } else {
            setPopUpHandling((prev) => ({ ...prev, message: respData.msg }));
            setIsVisible(true);
          }
        }
      } else {
        throw new Error(respData.msg);
      }
    } catch (error) {
      setPopUpHandling((prev) => ({ ...prev, message: error.message }));
      setIsVisible(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (otpState === 0 && !email) {
      setPopUpHandling((prev) => ({
        ...prev,
        message: 'Email is required',
      }));
      setIsVisible(true);
      return;
    }
    if (otpState === 1 && !otp) {
      setPopUpHandling((prev) => ({
        ...prev,
        message: 'Email and OTP is required',
      }));
      setIsVisible(true);
      return;
    }

    if (
      otpState === 2 &&
      (!email || !password || !confirmPassword || !phone || !name)
    ) {
      setPopUpHandling((prev) => ({
        ...prev,
        message: 'Please fill in all fields.',
      }));
      setIsVisible(true);
      return;
    }

    if (otpState === 2 && password !== confirmPassword) {
      setPopUpHandling((prev) => ({
        ...prev,
        message: 'Passwords do not match.',
      }));
      setIsVisible(true);
      return;
    }

    if (otpState === 2 && !/^\d{10}$/.test(phone)) {
      setPopUpHandling((prev) => ({
        ...prev,
        message: 'Phone number must be 10 digits.',
      }));
      setIsVisible(true);
      return;
    }
    if(otpState === 0){
      handleOtpVerification('onlyEmail');
    }
    if(otpState === 1){
      handleOtpVerification('submitOtp');
    }
    const payload = {
      email,
      mobile: phone,
      password,
      name,
      user_type: 'Company',
    };
    await callApi('submit','api/register', payload);
  };

  const handleOtpVerification = async (type) => {
    // e.preventDefault();
    if (type === 'onlyEmail') {
      var payload = {
        email: email,
      };
      await callApi('submitEmail', 'api/otp/generate', payload);
    } else {
      var payload = {
        email: email,
        otp: otp,
      };
      await callApi('submitOtp', 'api/otp/verify', payload);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center space-y-4 px-4 py-6 md:px-8 md:py-8 lg:px-12 lg:py-12">
      <Breadcrumb show={false} pageName="Sign In / Sign up" />
      <h1 className="text-4xl font-semibold py-5">Register for Free</h1>

      <div className="rounded-sm border w-full lg:w-2/3 border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="flex flex-col xl:flex-row items-center">
          <div className="hidden xl:block xl:w-1/2 xl:relative xl:flex xl:items-center xl:justify-center xl:py-12 xl:px-16">
            <div className="text-center xl:text-left">
              <Link className="mb-5 inline-block" to="/">
                <img src={require('../../assets/images/logo.png')} alt="Logo" />
              </Link>
              <p className="text-title-md font-semibold mb-4">
                Bring your brand to life with <br /> Ads
              </p>
              <img
                src={signupImage}
                alt="signup"
                className="w-full max-w-xs mx-auto xl:mx-0 xl:max-w-md"
              />
            </div>
          </div>

          <div className="w-full xl:w-1/2 px-4 py-6 xl:px-12 xl:py-12">
            {isLoading && <Loader />}
            {isVisible && (
              <PopUpAlert
                title={popupHandling.title}
                message={popupHandling.message}
                setIsVisible={setIsVisible}
                okayText={popupHandling.okayText}
                okayColor={popupHandling.okayColor}
                setClickOkay={() => setIsVisible(false)}
              />
            )}

            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="mb-2.5 font-medium">Email</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  className="w-full rounded-lg border py-3 px-4 border-slate-300"
                  required
                  disabled={otpState > 0}
                />
              </div>
              {otpState === 1 && (
                <div className="mb-4">
                  <label className="mb-2.5 font-medium">OTP</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setOtp(e.target.value)}
                    placeholder="Enter your email"
                    className="w-full rounded-lg border py-3 px-4 border-slate-300 my-2"
                    required
                  />
                  <label className="text-success bg-success p-1 px-2 rounded-lg bg-opacity-10">
                    {' '}
                    OTP sent on your email address {email}
                  </label>
                </div>
              )}
              {otpState === 2 && (
                <label className="text-success bg-success p-1 px-2 rounded-lg bg-opacity-10">
                  {' '}
                  OTP has been verified successfully{' '}
                </label>
              )}
              <div className="flex justify-end my-2">
                <button
                  type="submit"
                  className="flex h-12 w-full justify-center items-center rounded-lg bg-blue-600 text-white transition hover:bg-blue-700"
                >
                  {otpState === 0 ? 'Send OTP' : 'Verify OTP'}
                </button>
              </div>
            </form>
            {otpState === 2 && (
              <form onSubmit={handleSubmit}>
                {!isVisible && (
                  <>
                    <div className="mb-4">
                      <label className="mb-2.5 font-medium">Name</label>
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Enter full name"
                        className="w-full rounded-lg border py-3 px-4 border-slate-300"
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label className="mb-2.5 font-medium">Phone Number</label>
                      <input
                        type="text"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="Enter Phone number"
                        className="w-full rounded-lg border py-3 px-4 border-slate-300"
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label className="mb-2.5 font-medium">Password</label>
                      <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter your password"
                        className="w-full rounded-lg border py-3 px-4 border-slate-300"
                        required
                      />
                    </div>
                    <div className="mb-6">
                      <label className="mb-2.5 font-medium">
                        Confirm Password
                      </label>
                      <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="Confirm your password"
                        className="w-full rounded-lg border py-3 px-4 border-slate-300"
                        required
                      />
                    </div>
                    <div className="flex justify-end">
                      <button
                        type="submit"
                        className="flex h-12 w-full justify-center items-center rounded-lg bg-blue-600 text-white transition hover:bg-blue-700"
                      >
                        Sign Up
                      </button>
                    </div>
                  </>
                )}
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
