import React from 'react';
// import React, { useEffect, useState } from 'react';
import './home.css';
// import randomImg from '../../assets/images/avatar.png';
import FifthBlock from '../../components/homepageComponents/Fifth/FifthBlock';
import FirstBlock from '../../components/homepageComponents/First/FirstBlock';
import Footer from '../../components/homepageComponents/Footer/Footer';
import ForthBlock from '../../components/homepageComponents/Forth/ForthBlock';
import SecondBlock from '../../components/homepageComponents/Second/SecondBlock';
import SixthBlock from '../../components/homepageComponents/Sixth/SixthBlock';
import ThirdBlock from '../../components/homepageComponents/third/ThirdBlock';

const Home = () => {
  return (
    <>
      <section className="flex flex-col justify-center items-center  p-10 lg:p-12 space-y-20 lg:space-y-40 bg-white">
        <FirstBlock />
        <SecondBlock />
        <SixthBlock />
        <FifthBlock />
        <ForthBlock />
        <ThirdBlock />
        <Footer />
      </section>
    </>
  );
};

export default Home;
