import React from 'react';
import PrimeLogo from '../../../assets/images/home/amazon.png';
import KfcLogo from '../../../assets/images/home/kfc.png';
import McDonaldLogo from '../../../assets/images/home/mcdonald.png';
import NykaLogo from '../../../assets/images/home/nykaa.png';

const SixthBlock = () => {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-center text-4xl font-semibold leading-8 text-gray-900">
          Trusted by <span className="text-success">brands</span> like
        </h2>
        <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-4">
          <img
            className="col-span-2 max-h-24 w-36 object-contain lg:col-span-1"
            src={NykaLogo}
            alt="logo 1"
          />
          <img
            className="col-span-2 max-h-20 w-36 object-contain lg:col-span-1"
            src={McDonaldLogo}
            alt="logo 2"
          />
          <img
            className="col-span-2 max-h-24 w-36 object-contain lg:col-span-1"
            src={PrimeLogo}
            alt="logo 3"
          />
          <img
            className="col-span-2 max-h-36 w-36 object-contain lg:col-span-1"
            src={KfcLogo}
            alt="logo 4"
          />
        </div>
      </div>
    </div>
  );
};
export default SixthBlock;
