import React, { useEffect, useState, useCallback } from 'react';
import { Loader } from '../../common/Loader';
import useApiCalls from '../../utility/API/APICalls';

const BidStrategy = ({ setBidStrategy, adFormat }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [strategy, setStrategy] = useState('');
  const [strategyMsg, setStrategyMsg] = useState('');
  const [description, setDescription] = useState('');
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const apicalls = useApiCalls();

  const callApi = useCallback(
    async (endpoint) => {
      if (endpoint) {
        setIsLoading(true);
        try {
          const response = await apicalls(endpoint, 'GET');
          if (response.status === 200 && response.data.data) {
            const formattedData = formatBidStrategyData(response.data.data);
            setData(formattedData);
          }
        } catch (error) {
          console.error('API Call Error:', error);
        } finally {
          setIsLoading(false);
        }
      } else {
        setData([]);
      }
    },
    [apicalls],
  );

  const formatBidStrategyData = (responseData) => {
    return responseData.reduce((acc, item) => {
      acc[item.value] = {
        id: item.id,
        value: item.value,
        description: item.description,
        explanation: item.explanation,
      };
      return acc;
    }, {});
  };

  useEffect(() => {
    if (data.length === 0) {
      callApi('api/bidstrategy');
    }
  }, []);

  useEffect(() => {
    const selectedBidStrategy = determineBidStrategy(adFormat);
    setStrategy(selectedBidStrategy);
    setBidStrategy(selectedBidStrategy);
    setStrategyMsg(data[selectedBidStrategy]?.description);
    setDescription(data[selectedBidStrategy]?.explanation);
  }, [data, adFormat, setBidStrategy]);

  const determineBidStrategy = (format) => {
    if (format === 'Inside Cab Video Ads') {
      return 'Maximum CPV';
    } else if (
      ['Outside Cab Print Ads', 'Seat Back Laminated Branding'].includes(format)
    ) {
      return 'Minimum Count';
    } else {
      return '';
    }
  };

  const handleMouseEnter = () => setShowPopup(true);
  const handleMouseLeave = () => setShowPopup(false);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <div className="flex flex-col justify-center items-start m-5">
        <div className="flex flex-col space-y-5">
          <p>
            Your default bid strategy is <strong>{strategy}</strong>
          </p>
          <p style={{ fontSize: 12 }} className="text-slate-500">
            {strategyMsg}
          </p>
        </div>
      </div>
      <div className="group relative inline-block left-0">
        <button
          className="text-blue-300"
          style={{ fontSize: 16 }}
          onMouseOver={handleMouseEnter}
          onMouseOut={handleMouseLeave}
        >
          See how it works
        </button>
        {showPopup && (
          <p
            className="text-slate-600 bg-slate-300 p-2 rounded-lg bg-opacity-30 opacity-70"
            style={{ fontSize: 12 }}
          >
            {description}
          </p>
        )}
      </div>
    </>
  );
};

export default BidStrategy;
