import React, { useEffect, useState, useCallback } from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { MdClear } from 'react-icons/md';
import './DragNdrop.css';
import useApiCalls from '../../utility/API/APICalls';
import PopUpAlert from '../Alerts/PopUpAlert';

const DragNdrop = ({
  onFilesSelected,
  width,
  height,
  adFormat,
  title = 'Drag and drop your Advertisement file here',
  download_file_url = '',
  formattedAd = false,
  current_status,
  current_data,
}) => {
  const [file, setFile] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [acceptedTypes, setAcceptedTypes] = useState('');
  const [sizeLimit, setSizeLimit] = useState(0); // Add state for file size limit

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      const selectedFile = selectedFiles[0];
      if (acceptedTypes.includes(selectedFile.type)) {
        if (selectedFile.size <= sizeLimit) {
          setFile(selectedFile);
          setIsVisible(false);
        } else {
          setIsVisible(true);
        }
      } else {
        setIsVisible(true);
      }
    }
  };

  const editable = ['fresh', 'draft'].includes(
    current_status?.trim().toLowerCase(),
  );

  console.log('current_status_current_status', current_status, current_data);
  const [isLoading, setIsLoading] = useState(false);

  const apicalls = useApiCalls();
  const callApi = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await apicalls('api/signed_url', 'POST', {
        file_paths: [current_data],
      });
      if (
        (response.status === 200 || response.status === 201) &&
        response.data.data?.signed_file_paths
      ) {
        onFilesSelected(response.data.data.signed_file_paths[0]);
      } else {
        onFilesSelected('');
      }
    } catch (error) {
      console.log('errorerror', error);
      onFilesSelected('');
    } finally {
      setIsLoading(false);
    }
  }, [apicalls]);

  useEffect(() => {
    if (current_data) {
      callApi();
    }
  }, [current_data]);

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;

    if (droppedFiles.length > 0) {
      const newFile = droppedFiles[0];

      // Directly update the file without confirmation
      if (acceptedTypes.includes(newFile.type)) {
        if (newFile.size <= sizeLimit) {
          setFile(newFile);
          setIsVisible(false);
        } else {
          setIsVisible(true);
        }
      } else {
        setIsVisible(true);
      }
    }
  };

  const downloadFile = () => {
    const anchor = document.createElement('a');
    anchor.href = download_file_url;
    anchor.download = '';
    anchor.target = '_blank'; // Open in a new tab
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const handleRemoveFile = () => {
    setFile(null);
    setIsVisible(false);
  };

  useEffect(() => {
    const acceptedType =
      adFormat === 'Inside Cab Video Ads'
        ? ['video/mp4', 'video/mov', 'video/x-matroska', 'video/x-msvideo']
        : ['image/png', 'image/jpeg'];
    setAcceptedTypes(acceptedType);

    // Set file size limit based on adFormat
    const MB15 = 9.9 * 1024 * 1024;
    const MB2 = 2 * 1024 * 1024;
    const limit = adFormat === 'Inside Cab Video Ads' ? MB15 : MB2; // 15 MB or 2 MB
    setSizeLimit(limit);
  }, [adFormat]);

  useEffect(() => {
    if (file) {
      if (formattedAd) {
        onFilesSelected(title.split(' ')[0], file);
      } else {
        onFilesSelected(file);
      }
    } else {
      if (formattedAd) {
        onFilesSelected(title.split(' ')[0], '');
      } else {
        onFilesSelected('');
      }
    }
  }, [file, onFilesSelected, formattedAd, title]);

  return (
    <>
      {isVisible && (
        <PopUpAlert
          title="Invalid File"
          message="Unsupported file type or file size exceeded. Please select a valid file."
          okayText="Okay"
          okayColor="danger"
          setClickOkay={() => setIsVisible(false)}
          setIsVisible={setIsVisible}
        />
      )}
      <section
        className="drag-drop border-2 border-slate-300 shadow-3 rounded-lg"
        style={{ width: width, height: height }}
      >
        <div
          className={`document-uploader ${file ? 'upload-box active' : 'upload-box'}`}
          onDrop={handleDrop}
          onDragOver={(event) => event.preventDefault()}
        >
          <>
            {!file && editable && (
              <div className="flex justify-center items-center flex-col upload-info border border-dashed mr-2 rounded-lg py-1">
                <AiOutlineCloudUpload />
                <div>
                  <p className="">{title}</p>
                  <p className="text-red-600 text-xs">
                    File Size Limit :{' '}
                    {adFormat === 'Inside Cab Video Ads' ? '10 MB' : '2 MB'}
                    <br />
                    Supported formats:{' '}
                    {adFormat === 'Inside Cab Video Ads'
                      ? '.mp4, .mov, .mkv, .avi'
                      : '.png, .jpg, .jpeg'}
                  </p>
                </div>
              </div>
            )}
            <div className="flex flex-row  justify-center items-center space-x-2 py-1 mr-1">
              {download_file_url && (
                <button
                  className="flex justify-evenly items-center space-x-2 bg-green-500 bg-opacity-20 rounded-md w-8/12 px-1"
                  onClick={downloadFile}
                >
                  <svg
                    className="w-8 h-8 text-gray-800 dark:text-white text-success"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 15v2a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-2m-8 1V4m0 12-4-4m4 4 4-4"
                    />
                  </svg>
                  <h1 className="text-sm text-success">
                    Creative size details
                  </h1>
                </button>
              )}
              {editable && (
                <label
                  htmlFor="browse"
                  type="button"
                  className="text-center font-sm text-primary bg-primary bg-opacity-10 px-1 rounded-md hover:cursor-pointer"
                >
                  Browse file
                </label>
              )}
            </div>
            {editable && (
              <input
                type="file"
                hidden
                id="browse"
                onChange={handleFileChange}
                accept={
                  adFormat === 'Inside Cab Video Ads'
                    ? '.mp4,.mov,.mkv,.avi'
                    : '.png,.jpg,.jpeg'
                }
              />
            )}
          </>

          {file && editable && (
            <div className="file-list">
              <div className="file-list__container">
                <div className="file-item">
                  <div className="file-info">
                    <p>{file.name}</p>
                  </div>
                  <div className="file-actions">
                    <MdClear onClick={handleRemoveFile} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default DragNdrop;
