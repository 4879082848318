// import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import DropdownMessage from "./DropdownMessage"
import logoIcon from '../../assets/images/logo.png';
import DropdownNotification from './DropdownNotification';
import DropdownUser from './DropdownUser';
// import Cookies from 'js-cookie';

const Header = (props) => {
  return (
    <>
      <header
        className={`sticky top-0 z-10 flex w-full bg-white dark:bg-boxdark ${props.showShadow ? 'drop-shadow-lg' : ''}`}
      >
        <div
          className={`flex flex-grow items-center justify-between px-4 py-4 ${props.showShadow ? 'shadow-2' : ''} md:px-6 2xl:px-11`}
        >
          <div className="flex items-center gap-2 sm:gap-4">
            {/* <!-- Hamburger Toggle BTN --> */}
            {props.isLoggedIn && props.showSideBarIconOnMobile && (
              <button
                aria-controls="sidebar"
                onClick={(e) => {
                  e.stopPropagation();
                  props.setSidebarOpen(!props.sidebarOpen);
                }}
                className={`z-99999 block rounded-sm ${props.showShadow ? 'border border-stroke shadow-sm' : ''} bg-white p-1.5 dark:border-strokedark dark:bg-boxdark lg:hidden`}
              >
                <span className="relative block h-5.5 w-5.5 cursor-pointer">
                  <span className="du-block absolute right-0 h-full w-full">
                    <span
                      className={`relative left-0 top-0 my-1 block h-0.5 w-0 rounded-sm bg-black delay-[0] duration-200 ease-in-out dark:bg-white ${
                        !props.sidebarOpen && '!w-full delay-300'
                      }`}
                    ></span>
                    <span
                      className={`relative left-0 top-0 my-1 block h-0.5 w-0 rounded-sm bg-black delay-150 duration-200 ease-in-out dark:bg-white ${
                        !props.sidebarOpen && 'delay-400 !w-full'
                      }`}
                    ></span>
                    <span
                      className={`relative left-0 top-0 my-1 block h-0.5 w-0 rounded-sm bg-black delay-200 duration-200 ease-in-out dark:bg-white ${
                        !props.sidebarOpen && '!w-full delay-500'
                      }`}
                    ></span>
                  </span>
                  <span className="absolute right-0 h-full w-full rotate-45">
                    <span
                      className={`absolute left-2.5 top-0 block h-full w-0.5 rounded-sm bg-black delay-300 duration-200 ease-in-out dark:bg-white ${
                        !props.sidebarOpen && '!h-0 !delay-[0]'
                      }`}
                    ></span>
                    <span
                      className={`delay-400 absolute left-0 top-2.5 block h-0.5 w-full rounded-sm bg-black duration-200 ease-in-out dark:bg-white ${
                        !props.sidebarOpen && '!h-0 !delay-200'
                      }`}
                    ></span>
                  </span>
                </span>
              </button>
            )}
            {/* <!-- Hamburger Toggle BTN --> */}

            {props.showLogo && (
              <Link
                className="flex justify-center items-center bg-black rounded-lg"
                to="/"
              >
                <img src={logoIcon} alt="Logo" className="w-9 h-9 ml-1 my-1" />
                {/* <h2 className='font-bold text-2xl text-white'>BounceAd</h2> */}
                <h2 className="bg-black rounded-lg text-white text-2xl font-medium mx-1">
                  BounceAd
                </h2>
              </Link>
            )}
          </div>

          <div className="hidden sm:block"></div>

          <div className="flex items-center gap-3 2xsm:gap-7">
            <ul className="flex items-center gap-2 2xsm:gap-4">
              {/* <!-- Notification Menu Area --> */}
              {props.isLoggedIn && <DropdownNotification />}
              {/* <!-- Notification Menu Area --> */}
            </ul>

            {/* <!-- User Area --> */}
            {!props.isLoggedIn && (
              <Link to="/login">
                <button className="rounded-lg bg-gray py-2 px-4 text-lg font-medium justify-center items-center text-black shadow-card hover:bg-white hover:shadow-card dark:bg-boxdark dark:text-white dark:hover:bg-boxdark">
                  Login
                </button>
              </Link>
            )}
            {props.isLoggedIn && <DropdownUser user={props.user} />}
            {/* <!-- User Area --> */}
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
