import React, { useState, useEffect, useCallback } from 'react';
// import { useNavigate } from 'react-router-dom';
import PopUpAlert from '../../../common/Alerts/PopUpAlert';
import Success from '../../../common/Alerts/Success/Success';
import { Loader } from '../../../common/Loader/index';
import Accordion from '../../../common/accordian/Accordian';
import Breadcrumb from '../../../components/Breadcrumbs/Breadcrumb';
import Adformat from '../../../components/CampaignFormElements/Adformat';
import Bid from '../../../components/CampaignFormElements/Bid';
import BidStrategy from '../../../components/CampaignFormElements/BidStrategy';
import BudgetDate from '../../../components/CampaignFormElements/BudgetDate';
import CampaignName from '../../../components/CampaignFormElements/CampaignName';
import CampaignType from '../../../components/CampaignFormElements/CampaignType';
import Demography from '../../../components/CampaignFormElements/Demography';
import ExternalUrl from '../../../components/CampaignFormElements/ExternalUrl';
import Location from '../../../components/CampaignFormElements/Location';
import YourAd from '../../../components/CampaignFormElements/YourAd/YourAd';
import DefaultLayout from '../../../layout/DefaultLayout';
import useApiCalls from '../../../utility/API/APICalls';
import { useUploadFile, useGetSignedUrl } from '../../../utility/API/FileApis';
import useSaveCampaign from './Save';

const NewCampaign = () => {
  // const navigate = useNavigate();

  // your advertisement component
  const [showSkip, setShowSkip] = useState(false);
  const [uploadProgressStatus, setUploadProgressStatus] = useState(0);
  // modal fields and methods
  const [isVisible, setIsVisible] = useState(false);
  const [modaltitle, setModalTitle] = useState('');
  const [modalMsg, setModalMsg] = useState(
    'Are you sure you want to cancel? All of your data will be permanently removed. This action cannot be undone.',
  );
  const [showOk, setShowOk] = useState(true);
  const [modalColor, setModalColor] = useState('success');
  const [isLoading, setIsLoading] = useState(false);
  const [successVisibility, setSuccessVisibility] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [formsData, setFormsData] = useState({});
  const [durationInSeconds, setDurationInSeconds] = useState(0);

  const cancelForm = () => {
    setIsVisible(true);
    setModalTitle('Are you sure ? ');
    setModalMsg(
      'Your progress will not be saved, are you sure you want to cancel the campaign ? ',
    );
    setModalColor('red-600');
  };

  // modal fields end

  // apis calls
  const uploadFile = useUploadFile();
  const apiCalls = useApiCalls();
  const saveCampaign = useSaveCampaign();
  // file upload end

  // form fields and methods
  const [campaignName, setCampaignname] = useState('');
  const [bidStrategy, setBidStrategy] = useState('');
  const [budgetAndDates, setBudgetAndDates] = useState({});
  const [budgetDateHeading, setBudgetDateHeading] = useState('');
  const [location, setLocation] = useState([]);
  const [locationHeading, setLocationHeading] = useState('');
  const [demographyDetails, setDemographyDetails] = useState({
    age: [],
    gender: [],
  });
  const [userInterectionDetails, setUserInterectionDetails] = useState({});
  const [userInterectionDetailsHeading, setUserInterectionDetailsHeading] =
    useState({});
  const [demographyHeading, setDemographyHeading] = useState('');
  const [bid, setBid] = useState('');
  const [adType, setAdType] = useState({});
  const [uploadedFile, setUploadedFile] = useState([]);
  const [carFileSizePrint, setFileSizePrint] = useState({
    indica: '',
    swift: '',
    wagonr: '',
    etios: '',
    ritz: '',
    xcent: '',
  });
  const [fileUrl, setfileUrl] = useState('');
  const [adFormat, setAdFormat] = useState({});

  // handling red bouncary of errors upon submit
  const [campaignNameError, setCampaignNameError] = useState(false);
  const [adFormatError, setAdFormatError] = useState(false);
  const [budgetAndDatesError, setBudgetAndDatesError] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [adTypeError, setAdTypeError] = useState(false);
  const [demographyDetailsError, setDemographyDetailsError] = useState(false);
  const [bidError, setBidError] = useState(false);
  const [videoDurationError, setVideoDurationError] = useState(false);
  const [userInterectionDetailsError, setUserInterectionDetailsError] =
    useState(false);
  // handling red bouncary of errors upon submit end

  useEffect(() => {
    // console.log('carFileSizePrint ====', carFileSizePrint);
    // console.log('fileUrl ====', fileUrl);
    // console.log('uploadProgressStatus ====', uploadProgressStatus);
  }, [fileUrl, carFileSizePrint, uploadProgressStatus]);

  const setFileSizeFile = useCallback(
    (carType, file) => {
      // console.log('==================', carType, file)
      setFileSizePrint((prev) => ({
        ...prev,
        [carType?.toLowerCase()]: file,
      }));
    },
    [setFileSizePrint],
  );

  useEffect(() => {
    console.log('userInterectionDetails', userInterectionDetails);
    setUserInterectionDetailsHeading(
      `${userInterectionDetails.value?.value ? userInterectionDetails.value?.value : ''} | ${userInterectionDetails.link ? userInterectionDetails.link : ''}`,
    );
  }, [userInterectionDetails]);

  useEffect(() => {
    console.log('budgetAndDates', budgetAndDates);
    const setbudgetDateHeading = () => {
      if (
        bidStrategy === 'Maximum CPV' &&
        budgetAndDates.budgetType &&
        budgetAndDates.amount &&
        // budgetAndDates.startDate &&
        budgetAndDates.endDate
      ) {
        setBudgetDateHeading(
          `Budget type : ${budgetAndDates.budgetType} | ₹ ${budgetAndDates.amount} | From ${budgetAndDates.startDate} to ${budgetAndDates.endDate}`,
        );
      } else if (bidStrategy === 'Minimum Count' && budgetAndDates.startDate) {
        setBudgetDateHeading(
          `Months : ${budgetAndDates.months} | Cabs : ${budgetAndDates.cabs} | From ${budgetAndDates.startDate} | ₹ ${budgetAndDates.amount}`,
        );
      } else {
        setBudgetDateHeading('');
      }
    };

    setbudgetDateHeading();
  }, [budgetAndDates, bidStrategy]);

  useEffect(() => {
    setDemographyHeading(
      `Age Group : ${demographyDetails.age.map((item) => item.value).join(', ')} | Gender : ${demographyDetails.gender.map((item) => item.value).join(', ')}`,
    );
  }, [demographyDetails]);

  useEffect(() => {
    if (adType.value === 'Skipable engaging ads') {
      setShowSkip(true);
    } else {
      setShowSkip(false);
    }
  }, [adType]);

  useEffect(() => {
    let fileUrl = '';
    if (uploadedFile instanceof Blob) {
      fileUrl = URL.createObjectURL(uploadedFile);
    }
    setfileUrl(fileUrl);
  }, [uploadedFile]);

  // handling red bouncary of errors upon submit
  useEffect(() => {
    if (campaignName) {
      setCampaignNameError(false);
    }
    if (adFormat.value) {
      setAdFormatError(false);
    }
    if (budgetAndDates) {
      setBudgetAndDatesError(false);
    }
    if (location) {
      setLocationError(false);
    }
    if (adType.value) {
      setAdTypeError(false);
    }
    if (
      demographyDetails.age.map((item) => item.value).length > 0 &&
      demographyDetails.gender.map((item) => item.value).length > 0
    ) {
      setDemographyDetailsError(false);
    }
    if (bid) {
      setBidError(false);
    }
    if (
      userInterectionDetails.value &&
      userInterectionDetails.text_data &&
      userInterectionDetails.link
    ) {
      setUserInterectionDetailsError(false);
    }

    setFormsData({
      campaignName: campaignName,
      adFormat: adFormat,
      budgetAndDates: budgetAndDates,
      location: location,
      adType: adType,
      demographyDetails: demographyDetails,
      bid: bid,
      durationInSeconds: durationInSeconds,
      userInterectionDetails: userInterectionDetails,
    });
  }, [
    campaignName,
    adFormat,
    budgetAndDates,
    location,
    adType,
    demographyDetails,
    bid,
    videoDurationError,
  ]);

  //  handling error boundried end

  useEffect(() => {
    if (location.length === 0) {
      setLocationHeading('');
      return;
    } else {
      setLocationHeading(location.map((obj) => obj.value).join(', '));
    }
  }, [location]);

  // form fields and methods end

  // submit form
  const submitForm = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      if (!campaignName) {
        setCampaignNameError(true);
        setIsLoading(false);
        return;
      }
      if (!adFormat.value) {
        setAdFormatError(true);
        setIsLoading(false);
        return;
      }
      if (!budgetDateHeading) {
        setBudgetAndDatesError(true);
        setIsLoading(false);
        return;
      }
      if (!locationHeading) {
        setLocationError(true);
        setIsLoading(false);
        return;
      }
      // specifi checks for digital video ads
      if (adFormat.value === 'Inside Cab Video Ads') {
        if (!adType.value) {
          setAdFormatError(true);
          setIsLoading(false);
          return;
        }
        if (
          adFormat.value === 'Inside Cab Video Ads' &&
          adType.value === 'High Engagement Non-Skipable'
        ) {
          if (
            !userInterectionDetails.value?.value ||
            !userInterectionDetails.text_data ||
            !userInterectionDetails.link
          ) {
            setUserInterectionDetailsError(true);
            setIsLoading(false);
            return;
          }
        }
        if (
          demographyDetails.age.map((item) => item.value).length <= 0 ||
          demographyDetails.gender.map((item) => item.value).length <= 0
        ) {
          setDemographyDetailsError(true);
          setIsLoading(false);
          return;
        }
        if (!bid) {
          setBidError(true);
          setIsLoading(false);
          return;
        }
      }

      // specifi checks for digital video ads ends

      if (adFormat.value === 'Outside Cab Print Ads') {
        const falseCounts = Object.entries(carFileSizePrint)
          .map(([key, val]) => val === '')
          .filter((isFalse) => !isFalse).length;

        if (falseCounts < 3) {
          setIsVisible(true);
          setShowOk(false);
          setModalColor('red-600');
          setModalTitle('Error');
          setModalMsg(`Please upload at least ${3 - falseCounts} more files`);
          setIsLoading(false);
          return;
        }
      } else {
        if (!uploadedFile) {
          setIsVisible(true);
          setShowOk(false);
          setModalColor('red-600');
          setModalTitle('Error');
          setModalMsg(`Please upload your advertisement file`);
          setIsLoading(false);
          return;
        }
      }

      let upload_multiple = false;

      if (adFormat.value !== 'Outside Cab Print Ads') {
        setFileSizePrint((prev) =>
          Object.entries(prev).map(([key, val]) => {
            return { [key]: '' };
          }),
        );
      } else {
        setUploadedFile('');
        upload_multiple = true;
      }

      // upload file inside submit form
      if (!upload_multiple) {
        const response = await uploadFile(
          uploadedFile,
          setUploadProgressStatus,
        );
        formsData['file_url'] = response.data.data.file_url;
        // console.log('file_url', response.data.data.file_url);
      }
      formsData['duration'] = durationInSeconds;

      const isFailed = await saveCampaign(formsData); // isFailed return string if there is some error, otherwise returns false

      if (!isFailed) {
        setSuccessMsg(
          'Your Campaign have been saved successfully, proceeding to payment',
        );
        setSuccessVisibility(true);
      } else {
        setIsVisible(true);
        setModalTitle('Error');
        setModalMsg(isFailed);
      }

      setIsLoading(false);
    } catch (error) {
      console.log('error nEwCampaign', error);
      setIsVisible(true);
      setModalTitle('Error');
      setModalMsg('Some Error occured, please try again later');
    }
  };

  //  submit form end

  const setClickOkay = () => {
    setIsVisible(false);
    setIsLoading(true);
    setCampaignname('');
    setBidStrategy('');
    setBudgetAndDates({});
    setBudgetDateHeading('');
    setLocation([]);
    setDemographyDetails({ age: [], gender: [] });
    setDemographyHeading('');
    setBid('');
    setAdType({});
    setUploadedFile('');
    setfileUrl('');
    setAdFormat({});
    setUserInterectionDetails({});
    setIsLoading(false);
  };

  return (
    <DefaultLayout>
      {isVisible && (
        <PopUpAlert
          title={modaltitle}
          message={modalMsg}
          okayText="Okay"
          okayColor={modalColor}
          showOk={showOk}
          setClickOkay={setClickOkay}
          setIsVisible={setIsVisible}
        />
      )}
      {successVisibility && (
        <Success
          message={successMsg}
          setIsVisible={() => setSuccessVisibility(false)}
          reDirectTo="/campaigns"
        />
      )}

      <div className="mx-auto max-w-360">
        <Breadcrumb pageName="Add New Campaign" />
        {isLoading ? (
          <Loader />
        ) : (
          <div className="flex flex-col xl:flex-row gap-8">
            <div className="flex-1">
              <div className="border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark rounded-xl">
                <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
                  <h3 className="font-medium text-black dark:text-white">
                    New Campaign
                  </h3>
                </div>
                <div className="flex flex-col w-full mb-10">
                  <form action="#" onSubmit={(e) => e.preventDefault()}>
                    <Accordion
                      title="Campaign Name"
                      value={campaignName}
                      error={campaignNameError}
                    >
                      <CampaignName setValueCallback={setCampaignname} />
                    </Accordion>

                    {campaignName && (
                      <Accordion
                        title="Ad Format"
                        value={adFormat.value}
                        error={adFormatError}
                      >
                        <Adformat setAdFormat={setAdFormat} />
                      </Accordion>
                    )}

                    {adFormat.value && (
                      <Accordion
                        title="Bid Strategy"
                        value={bidStrategy}
                        error={false}
                      >
                        <BidStrategy
                          setBidStrategy={setBidStrategy}
                          adFormat={adFormat.value}
                        />
                      </Accordion>
                    )}

                    {adFormat.value && (
                      <Accordion
                        title="Budget and dates"
                        value={budgetDateHeading}
                        error={budgetAndDatesError}
                      >
                        <BudgetDate
                          setBudgetAndDates={setBudgetAndDates}
                          bidStrategy={bidStrategy}
                          adFormat={adFormat}
                        />
                      </Accordion>
                    )}

                    {budgetDateHeading && (
                      <Accordion
                        title="Locations"
                        value={locationHeading}
                        error={locationError}
                      >
                        <Location setLocation={setLocation} />
                      </Accordion>
                    )}

                    {location && adFormat.value === 'Inside Cab Video Ads' && (
                      <Accordion
                        title="Campaign Type"
                        value={adType.value}
                        error={adTypeError}
                      >
                        <CampaignType
                          setAdType={setAdType}
                          adFormat={adFormat}
                        />
                      </Accordion>
                    )}

                    {adFormat.value === 'Inside Cab Video Ads' &&
                      adType.value === 'High Engagement Non-Skipable' && (
                        <Accordion
                          title="User Interection Details"
                          value={userInterectionDetailsHeading}
                          error={userInterectionDetailsError}
                        >
                          <ExternalUrl
                            setValueCallback={setUserInterectionDetails}
                          />
                        </Accordion>
                      )}

                    {adType.value &&
                      adFormat.value === 'Inside Cab Video Ads' && (
                        <Accordion
                          title="People you want to reach"
                          value={demographyHeading}
                          error={demographyDetailsError}
                        >
                          <Demography
                            setDemographyDetails={setDemographyDetails}
                          />
                        </Accordion>
                      )}

                    {demographyDetails.age.map((item) => item.value).length >
                      0 &&
                      demographyDetails.gender.map((item) => item.value)
                        .length > 0 && (
                        <Accordion
                          title="Bid"
                          value={`₹ ${bid}`}
                          error={bidError}
                        >
                          <Bid
                            setBid={setBid}
                            adType={adType.value}
                            budgetAndDates={budgetAndDates}
                          />
                        </Accordion>
                      )}
                  </form>
                </div>
              </div>
            </div>

            <YourAd
              showSkip={showSkip}
              fileUrl={fileUrl}
              setUploadedFile={setUploadedFile}
              campaignName={campaignName}
              setFileSizeFile={setFileSizeFile}
              cancelForm={cancelForm}
              submitForm={submitForm}
              adFormat={adFormat.value}
              setDurationInSeconds={setDurationInSeconds}
              setVideoDurationError={setVideoDurationError}
            />
          </div>
        )}
      </div>
    </DefaultLayout>
  );
};

export default NewCampaign;
