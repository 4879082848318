import Cookies from 'js-cookie';
import React, { useState, useEffect } from 'react';
import { useLogout } from '../../utility/Auth/Auth';
import Header from '../Header';

const Navbar = ({
  showLogo,
  isLoggedIn,
  sidebarOpen,
  setSidebarOpen,
  showSideBarIconOnMobile = true,
  user = {},
  showShadow = true,
}) => {
  const [isLoggedInUser, setIsLoggedInUser] = useState(isLoggedIn);
  const [loggedInUserInfo, setLoggedInUserInfo] = useState(user);

  const { logoutUser } = useLogout();

  useEffect(() => {
    const token = Cookies.get('token');
    const u = Cookies.get('user');
    try {
      if (token && u) {
        const parsed_User = JSON.parse(u);
        setIsLoggedInUser(true);
        setLoggedInUserInfo(parsed_User);
      } else {
        setIsLoggedInUser(false);
      }
    } catch (error) {
      logoutUser();
    }
  }, []);

  // const [showlogo,setShowLogo] = useState(showLogo);

  return (
    <Header
      showLogo={showLogo}
      isLoggedIn={isLoggedInUser}
      sidebarOpen={sidebarOpen}
      setSidebarOpen={setSidebarOpen}
      user={loggedInUserInfo}
      showSideBarIconOnMobile={showSideBarIconOnMobile}
      showShadow={showShadow}
    />
    // <Header showLogo={showlogo} isLoggedIn={isLoggedInUser} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} user={loggedInUserInfo} showSideBarIconOnMobile={showSideBarIconOnMobile}/>
  );
};

export default Navbar;
