import React from 'react';

const ForthBlock = () => {
  return (
    <div className="bg-black w-full py-10 rounded-xl">
      <div className="mx-auto px-6 lg:px-8">
        <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
          <div className="mx-auto flex max-w-xs flex-col gap-y-4">
            <dt className="text-base leading-7 text-white">
              Impressions successfully delivered
            </dt>
            <dd className="order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl">
              2 mn +
            </dd>
          </div>
          <div className="mx-auto flex max-w-xs flex-col gap-y-4">
            <dt className="text-base leading-7 text-white ">
              Number of Clients{' '}
            </dt>
            <dd className="order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl">
              50 +
            </dd>
          </div>
          <div className="mx-auto flex max-w-xs flex-col gap-y-4">
            <dt className="text-base leading-7 text-white">
              Monthly active campaigns
            </dt>
            <dd className="order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl">
              10-15
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default ForthBlock;
