// import React from 'react'
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useGetSignedUrl } from '../API/FileApis';

function useLogin() {
  const navigate = useNavigate();
  const getSignedUrl = useGetSignedUrl();
  const loginUser = async (token, userInfo) => {
    // console.log('token,userInfo', token, userInfo);

    const resp = await getSignedUrl([userInfo.profile_pic]);
    userInfo.profile_pic = resp[0];
    console.log('userInfo', userInfo);
    Cookies.set('token', token, { expires: 1, secure: true, sameSite: 'Lax' });
    Cookies.set('user', JSON.stringify(userInfo), {
      expires: 1,
      secure: true,
      sameSite: 'Lax',
    });
    // console.log('token and userInfo set in cookies');
    navigate('/dashboard', { state: { userInfo } });
  };

  return { loginUser };
}

function useLogout() {
  const navigate = useNavigate();

  const logoutUser = async () => {
    Cookies.remove('token');
    Cookies.remove('user');
    navigate('/login');
  };

  return { logoutUser };
}

export { useLogin, useLogout };
