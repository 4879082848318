import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../common/Loader/index';
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb';
import CampaignList from '../../components/Tables/CampaignList';
import DefaultLayout from '../../layout/DefaultLayout';
import useApiCalls from '../../utility/API/APICalls';

const Campaigns = () => {
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [calledApi, setCalledApi] = useState(false);

  const callApi = async () => {
    setIsLoading(true);
    try {
      const response = await apicalls('api/adcampaign', 'GET');
      const respData = response?.data;
      if (response.status === 200) {
        if (respData.data) {
          setOptions(respData?.data);
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log('error', error);
      setOptions([]);
      setIsLoading(false);
    }
  };

  const apicalls = useApiCalls();
  useEffect(() => {
    const callingAPi = async () => {
      if (options.length <= 0 && !calledApi) {
        await callApi();
        setCalledApi(true);
      }
    };
    callingAPi();
  }, [options]);

  const redirectToAddNew = () => {
    navigate('/campaign/new');
  };

  const Button = () => (
    <button
      className="rounded bg-white py-3 px-3 text-xs items-center justify-center font-medium text-black shadow-card hover:bg-white hover:shadow-card dark:bg-boxdark dark:text-white dark:hover:bg-boxdark"
      onClick={redirectToAddNew}
    >
      Start New Campaign
    </button>
  );

  return (
    <DefaultLayout>
      <Breadcrumb pageName="Campaigns" Button={Button} />

      <div className="flex flex-col gap-10">
        {!isLoading && <CampaignList packageData={options} />}
        {isLoading && <Loader />}
      </div>
    </DefaultLayout>
  );
};

export default Campaigns;
