import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="flex justify-center h-screen lg:mt-10">
      <div className="flex flex-col space-y-10 p-5 lg:p-0 max-w-5xl w-full">
        <h1 className="text-start" style={{ fontSize: 50, fontWeight: 700 }}>
          Privacy Policy
        </h1>
        <div className="text-left space-y-4">
          <p>
            Last Updated: 30-Sep-2024 Qorvex Technologies Private Limited (“we”,
            “our”, or “us”), operating under the brand name BounceAd, is
            committed to protecting your privacy. This Privacy Policy explains
            how we collect, use, disclose, and safeguard your information when
            you use our services, including but not limited to the BounceAd app,
            website, and any other platforms or applications associated with our
            services. By accessing or using our services, you agree to this
            Privacy Policy. If you do not agree, please do not use our services.
          </p>
          <h2 className="self-start text-3xl font-semibold my-5">
            1. Information We Collect
          </h2>
          <p>
            We collect various types of information from and about users of our
            services, including:
          </p>
          <p>
            <b>1.1 Personal Information</b>
          </p>
          <p>
            <b>Contact Information:</b> Name, email address, phone number, and
            postal address.
            <br />
            <b>Account Information:</b> Username, password, and other
            information necessary to set up and maintain an account.
            <br />
            <b>Payment Information:</b> Details necessary to process payments,
            such as credit/debit card numbers, digital wallet details, and bank
            account information.
          </p>
          <p>
            <b>1.2 Non-Personal Information</b>
          </p>
          <p>
            <b>Usage Data:</b> Information about how you interact with our
            services, including access times, browser types, pages viewed, your
            IP address, and device identifiers.
            <br />
            <b>Location Data:</b> Geo-location information from your device if
            enabled.
            <br />
            <b>Technical Data:</b> Information collected via cookies, web
            beacons, and other technologies about your usage of our websites and
            apps.
          </p>
          <p>
            <b>1.3 Content Information</b>
            <br />
            <b>Advertiser Content:</b> Any videos, images, text, or other
            advertising materials uploaded by you for display through our
            services.
          </p>
          <p>
            <b>1.4 Data from Third Parties</b>
            <br />
            <b>Third-Party Integrations:</b> Information from third-party
            services if you choose to link, connect, or log in to our services
            through these third-party accounts (e.g., Google, Facebook).
          </p>

          <h2 className="self-start text-3xl font-semibold my-5">
            2. How We Use Your Information
          </h2>
          <p>
            We use the information we collect in various ways to operate,
            maintain, and enhance our services, including:
          </p>
          <p>
            <b>2.1 Service Delivery and Improvement</b>
          </p>
          <p>
            To provide, manage, and improve our advertising services and digital
            platforms.
            <br />
            To process your transactions and manage your Wallet and advertising
            campaigns.
            <br />
            To develop new features, products, or services that meet your needs.
          </p>
          <p>
            <b>2.2 Communications</b>
            <br />
            To contact you with updates, promotions, and relevant information
            regarding our services.
            <br />
            To respond to your inquiries and provide customer support.
          </p>
          <p>
            <b>2.3 Compliance and Protection</b>
            <br />
            To enforce our terms and conditions, privacy policy, and any other
            agreements.
            <br />
            To detect, investigate, and prevent fraudulent transactions, abuse,
            and other harmful activities.
            <br />
            To comply with legal obligations and respond to government requests.
          </p>

          <h2 className="self-start text-3xl font-semibold my-5">
            3. Sharing Your Information
          </h2>
          <p>
            We may share the information we collect about you in the following
            ways:
          </p>
          <p>
            <b>3.1 With Service Providers</b>
            <br />
            We share information with third-party service providers who perform
            services on our behalf, such as payment processing, data analysis,
            email delivery, hosting services, customer service, and marketing.
          </p>
          <p>
            <b>3.2 With Advertisers and Partners</b>
            <br />
            We may share non-personally identifiable information with
            advertisers and partners to help them understand our audience and
            improve their advertising effectiveness.
          </p>
          <p>
            <b>3.3 For Legal Compliance</b>
            <br />
            We may disclose information if required to do so by law or in the
            good faith belief that such action is necessary to comply with
            applicable laws, legal process, or requests from government
            authorities.
          </p>
          <p>
            <b>3.4 Business Transfers</b>
            <br />
            In connection with, or during negotiations of, any merger, sale of
            company assets, financing, or acquisition of all or a portion of our
            business to another company, we may share your information.
          </p>

          <h2 className="self-start text-3xl font-semibold my-5">
            4. Data Security
          </h2>
          <p>
            We implement reasonable security measures to protect your personal
            information from unauthorized access, use, or disclosure. However,
            please be aware that no electronic transmission or storage of
            information can be entirely secure. Therefore, while we strive to
            protect your personal data, we cannot guarantee its absolute
            security.
          </p>

          <h2 className="self-start text-3xl font-semibold my-5">
            5. Data Retention
          </h2>
          <p>
            We retain your personal information only for as long as necessary to
            fulfill the purposes for which it was collected, including for the
            purposes of satisfying any legal, accounting, or reporting
            requirements.
          </p>

          <h2 className="self-start text-3xl font-semibold my-5">
            6. Your Rights and Choices
          </h2>
          <p>
            Depending on your location, you may have certain rights regarding
            your personal information, including:
          </p>
          <p>
            <b>6.1 Access and Correction</b>
            <br />
            You can access and update your account information directly through
            our platform. You may also contact us to request access to, correct,
            or update any personal information we hold about you.
          </p>
          <p>
            <b>6.2 Opt-Out of Marketing Communications</b>
            <br />
            You may opt-out of receiving promotional emails or messages from us
            by following the instructions in those communications or contacting
            us directly.
          </p>
          <p>
            <b>6.3 Data Deletion</b>
            <br />
            You can request that we delete your personal information, subject to
            certain exceptions such as compliance with legal obligations or
            retention requirements.
          </p>

          <h2 className="self-start text-3xl font-semibold my-5">
            7. Third-Party Links
          </h2>
          <p>
            Our services may contain links to third-party websites or services
            that are not owned or controlled by Qorvex Technologies Private
            Limited. We are not responsible for the privacy practices or the
            content of such third-party websites or services.
          </p>

          <h2 className="self-start text-3xl font-semibold my-5">
            8. Changes to This Privacy Policy
          </h2>
          <p>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
            You are advised to review this Privacy Policy periodically for any
            changes.
          </p>

          <h2 className="self-start text-3xl font-semibold my-5">
            9. Contact Us
          </h2>
          <p>
            If you have any questions or concerns about this Privacy Policy,
            please contact us at support@bouncead.com.
          </p>

          <h1 className="my-24"></h1>
          <h2 className="flex justify-center items-center">
            ....................... END .....................
          </h2>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
