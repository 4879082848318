import React, { useState, useEffect, useCallback } from 'react';
import { Loader } from '../../common/Loader';
import useApiCalls from '../../utility/API/APICalls';

const CampaignType = ({
  setAdType,
  current_status = 'fresh',
  current_data = {},
}) => {
  const [selectedType, setSelectedType] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const handleAdTypeChange = (type, index) => {
    if (!type.id) {
      type['id'] = index + 1;
    }
    setSelectedType(type);
    setAdType(type);
  };

  const editable = ['fresh', 'draft'].includes(
    current_status?.trim().toLowerCase(),
  );

  useEffect(() => {
    if (current_data.value) {
      setSelectedType(current_data);
      setAdType(current_data);
    }
  }, []);

  const apicalls = useApiCalls();

  const callApi = useCallback(
    async (endpoint) => {
      if (endpoint) {
        setIsLoading(true);
        try {
          const response = await apicalls(endpoint, 'GET');
          if (response.status === 200 && response.data) {
            // console.log('CampaignType response.data', response.data.data);
            setData(response.data.data);
          }
        } catch (error) {
          console.error('API Call Error:', error);
        } finally {
          setIsLoading(false);
        }
      } else {
        setData([]);
      }
    },
    [apicalls],
  );

  useEffect(() => {
    if (data.length <= 0) {
      callApi('api/campaigntype');
    }
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <div className="flex flex-row justify-evenly overflow-x-auto gap-4 p-4">
      {data.map((item, index) => (
        <button
          key={item.value}
          className={`flex flex-col justify-center items-center w-64 min-w-60
            transition-transform duration-300 px-2 border-2 rounded-lg
            ${selectedType.value === item.value ? 'border-green-600 border-4 bg-gray-200 transform scale-100' : 'opacity-80 transform scale-75'}
            `}
          onClick={() => handleAdTypeChange(item, index)}
          disabled={!editable}
        >
          <h2
            className={`text-base font-semibold text-center md:text-lg ${selectedType.value === item.value ? 'text-green-600' : ''}`}
          >
            {item.value}
          </h2>
          <div className="flex flex-col items-center">
            <img
              src={item.image_url}
              alt={item.value}
              className="w-50 h-auto object-cover mb-2"
            />
            <p className="text-sm bg-yellow-100 p-2 rounded-md text-warning text-center break-words md:text-sm">
              {item.description}
            </p>
          </div>
        </button>
      ))}
    </div>
  );
};

export default CampaignType;
