import React from 'react';
import { Link } from 'react-router-dom';
import productImg from '../../../assets/images/home/Group 1 (2).png';

const FirstBlock = () => {
  return (
    <div className="flex flex-col lg:flex-row-reverse items-center justify-evenly w-full h-auto space-x-10">
      {/* Text Section */}
      <div className="flex flex-col justify-center items-center lg:items-center w-full lg:w-1/2 lg:ml-10">
        <h1 className="text-left lg:text-left text-2xl lg:text-5xl font-bold w-full lg:w-9/12 leading-snug">
          Transforming Everyday Spaces into Dynamic Advertising Platforms
        </h1>
        <h2 className="text-left lg:text-left text-lg lg:text-2xl mt-4 lg:mt-6 w-full lg:w-9/12 mb-10">
          Reach your audience through innovative on-cab ads, digital screens,
          and more.
        </h2>
        <button
          className=" bg-blue-600 hover:bg-blue-800 w-1/2 h-12 lg:w-4/12 rounded-xl text-white self-start lg:ml-25"
          variant="contained"
        >
          <Link to="/signup" className="font-semibold">
            Get started today
          </Link>
        </button>
      </div>
      {/* Image Section */}
      <div className="flex justify-center items-center w-full lg:w-1/2 mt-20 lg:mt-5 lg:ml-20 h-76">
        <img
          src={productImg}
          alt="product mockup"
          className="drop-shadow-2xl -ml-10 lg:ml-0"
        />
      </div>
    </div>
  );
};

export default FirstBlock;
