import React, { useEffect, useState } from 'react';
import Accordion from '../../common/accordian/Accordian';
import Age from './Age';
import Gender from './Gender';

const Demography = ({
  setDemographyDetails,
  current_status = 'fresh',
  current_data = {
    age_group: [],
    gender: [],
  },
}) => {
  const [gender, setGender] = useState([]);
  const [age, setAge] = useState([]);

  const [genderHeading, setGenderHeading] = useState('');
  const [ageHeading, setAgeHeading] = useState('');

  useEffect(() => {
    setDemographyDetails({ age: age, gender: gender });
    // setValue(`Selected Age Groups : ${age}`);
  }, [age, gender, setDemographyDetails]);

  useEffect(() => {
    setGenderHeading(gender.map((item) => item.value).join(', '));
  }, [gender]);

  useEffect(() => {
    setAgeHeading(age.map((item) => item.value).join(', '));
  }, [age]);

  return (
    <>
      <Accordion title="Gender" subType={true} value={genderHeading}>
        <Gender
          setGender={setGender}
          current_status={current_status}
          current_data={current_data.gender}
        />
      </Accordion>

      <Accordion title="Age" subType={true} value={ageHeading}>
        <Age
          setAge={setAge}
          current_status={current_status}
          current_data={current_data.age_group}
        />
      </Accordion>
    </>
  );
};

export default Demography;
