import React from 'react';
import './SecondBlock.css';

const SecondBlock = () => {
  return (
    <div className="flex flex-col-reverse lg:flex-row-reverse justify-evenly items-center p-2 bg-black rounded-3xl">
      {/* Text Section */}
      <div className="flex flex-col justify-center items-center w-full lg:w-1/2">
        <h1
          className="text-center lg:text-left text-2xl lg:text-4xl font-bold text-white"
          style={{ fontFamily: 'sans-serif' }}
        >
          Don't get lost in space of millions of marketing solutions.
        </h1>
        <h1 className="text-center lg:text-left text-xl lg:text-xl mt-4 lg:mt-3 text-white">
          Your one stop solution for all your marketing needs is BounceAd. we
          understand your business and try to add value to it.
        </h1>
      </div>

      {/* Astronaut Section */}
      <div className="flex justify-center items-center w-full lg:w-1/2 mt-10 lg:mt-0">
        <div
          data-js="astro"
          className="astronaut max-w-full max-h-full lg:ml-10"
        >
          <div className="head"></div>
          <div className="arm arm-left"></div>
          <div className="arm arm-right"></div>
          <div className="leg leg-left"></div>
          <div className="leg leg-right"></div>
          <div className="schoolbag"></div>
        </div>
      </div>
    </div>
  );
};

export default SecondBlock;
