const TransactionsTable = ({ transactions }) => {
  return transactions.map((transaction, key) => (
    <tr key={key}>
      <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
        <h5 className="font-bold text-black dark:text-white">
          {transaction.payment_id}
        </h5>
      </td>
      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
        <p className="text-black dark:text-white">{transaction.order_id}</p>
      </td>
      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
        <p className="text-black dark:text-white">{transaction.balance}</p>
      </td>
      <td className="border-b border-[#eee] dark:border-strokedark">
        <p
          className={`inline-flex rounded-md bg-opacity-10 py-1 px-1.5 text-sm font-medium
                        ${transaction.status === 'Pending' ? 'bg-warning text-warning' : ['Payment Success', 'Refund Success'].includes(transaction.status_shown_to_user) ? 'bg-success text-success' : 'bg-meta-1 text-meta-1'}`}
        >
          {transaction.status_shown_to_user}
        </p>
      </td>
      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
        <p className="text-black dark:text-white">{transaction.created_at}</p>
      </td>
      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
        <p className="text-black dark:text-white">{transaction.updated_at}</p>
      </td>
      <td className="flex justify-center items-center border-b border-[#eee] py-2 dark:border-strokedark">
        <button className="bg-slate-300 text-slate-500 px-2 py-1 mt-4 rounded-md hover:text-black duration-200 hover:scale-110">
          <svg
            className="w-6 h-6 text-gray-800 dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 13V4M7 14H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2m-1-5-4 5-4-5m9 8h.01"
            />
          </svg>
        </button>
      </td>
    </tr>
  ));
};

export default TransactionsTable;
