import { Helmet } from 'react-helmet-async';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
// import {useState,useEffect} from 'react';
import Navbar from './components/Navbar/Navbar';
import Login from './pages/Auth/Login';
import Signup from './pages/Auth/Signup';
import Blogs from './pages/Blogs/Blogs';
import Campaigns from './pages/Campaigns/Campaigns';
import EditCampaign from './pages/Campaigns/EditCampaign/EditCampaign';
import NewCampaign from './pages/Campaigns/NewCampaign/NewCampaign';
import Contact from './pages/Contact/Contact';
import Dashboard from './pages/Dashboard/Dashboard';
import Error505 from './pages/Error/Error505';
import Home from './pages/Home/Home';
import PrivacyPolicy from './pages/Privacypolicy';
import Profile from './pages/Profile/Profile';
import Settings from './pages/Settings';
import TermsAndConditions from './pages/Tnc';
import BillingHistory from './pages/payment/BillingHistory';
import Transactions from './pages/payment/Transactions';
import Wallets from './pages/payment/Wallets';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <>
        <Helmet>
          <title>BounceAd - India's No. 1 marketing platform</title>
          <meta
            name="description"
            content="An AdTech startup specializing in cab marketing, offering a comprehensive suite of both digital and traditional advertising solutions tailored to reach targeted audiences effectively."
          />
          <link rel="canonical" href="/" />
        </Helmet>
        <Navbar
          showLogo={true}
          showSideBarIconOnMobile={false}
          showShadow={false}
        />
        <Home />
      </>
    ),
  },
  {
    path: '/login',
    element: (
      <>
        <Helmet>
          <title>Login - BounceAd</title>
          <meta
            name="description"
            content="Login at BounceAd to access your account and manage your marketing campaigns."
          />
          <link rel="canonical" href="/login" />
        </Helmet>
        <Navbar showLogo={true} showSideBarIconOnMobile={false} />
        <Login />
      </>
    ),
  },
  {
    path: '/signup',
    element: (
      <>
        <Helmet>
          <title>BounceAd - Create an account</title>
          <meta
            name="description"
            content="create account for free @ bouncead and start your marketing journey"
          />
          <link rel="canonical" href="/signup" />
        </Helmet>
        <Navbar showLogo={true} showSideBarIconOnMobile={false} />
        <Signup />
      </>
    ),
  },
  {
    path: '/contact',
    element: (
      <>
        <Helmet>
          <title>Contact Us</title>
          <meta
            name="description"
            content="BounceAd support team, contact at support@bouncead.com"
          />
          <link rel="canonical" href="/contact" />
        </Helmet>
        <Navbar showLogo={true} showSideBarIconOnMobile={false} />
        <Contact />
      </>
    ),
  },
  {
    path: '/Profile',
    element: (
      <>
        <Helmet>
          <title>My Profile - BounceAd</title>
          <meta
            name="description"
            content="handle Profile of my BounceAd account"
          />
          <link rel="canonical" href="/profile" />
        </Helmet>
        <Profile />
      </>
    ),
  },
  {
    path: '/blogs',
    element: (
      <>
        <Helmet>
          <title>Blogs</title>
          <meta name="description" content="BounceAd Latest Blogs" />
        </Helmet>
        <Blogs />
      </>
    ),
  },
  {
    path: '/dashboard',
    element: (
      <>
        <Helmet>
          <title>BounceAd - Dashboard</title>
          <meta
            name="description"
            content="BounceAd - India's No. 1 marketing platform"
          />
          <link rel="canonical" href="/dashboard" />
        </Helmet>
        <Dashboard />
      </>
    ),
  },
  {
    path: '/campaigns',
    element: (
      <>
        <Helmet>
          <title>My Campaigns list</title>
          <meta
            name="description"
            content="manage and handle your campaigns at ease"
          />
          <link rel="canonical" href="/campaigns" />
          <meta name="robots" content="noindex" />
        </Helmet>
        <Campaigns />
      </>
    ),
  },
  {
    path: '/campaign/new',
    element: (
      <>
        {' '}
        <Helmet>
          <title>Start New Campaign</title>
          <meta name="description" content="BounceAd - new campaign" />
          <link rel="canonical" href="/campaign/new" />
        </Helmet>
        <NewCampaign />
      </>
    ),
  },
  {
    path: '/campaign/Edit/:id/:name',
    element: (
      <>
        <Helmet>
          <title>Edit Campaigns</title>
          <meta name="description" content="Editting campaign at BounceAd" />
          <link rel="canonical" href="/campiagn/edit/:id/:name" />
        </Helmet>
        <EditCampaign />
      </>
    ),
  },
  {
    path: '/billings',
    element: (
      <>
        <Helmet>
          <title>Campaign billings</title>
          <meta name="description" content="My Campaign Billings at BounceAd" />
          <link rel="canonical" href="/billings" />
          <meta name="robots" content="noindex" />
        </Helmet>
        <BillingHistory />
      </>
    ),
  },
  {
    path: '/Error/page/505',
    element: (
      <>
        {' '}
        <Helmet>
          <title>Error Occured</title>
          <meta
            name="description"
            content="Error 505 page, something went wrong"
          />
          <link rel="canonical" href="/error/page/505" />
          <meta name="robots" content="noindex" />
        </Helmet>
        <Error505 />
      </>
    ),
  },
  {
    path: '/transactions',
    element: (
      <>
        {' '}
        <Helmet>
          <title>Transactions - BounceAd</title>
          <meta
            name="description"
            content="Visibility of all your transactions"
          />
          <link rel="canonical" href="/transactions" />
          <meta name="robots" content="noindex" />
        </Helmet>
        <Transactions />
      </>
    ),
  },
  {
    path: '/payment',
    element: (
      <>
        {' '}
        <Helmet>
          <title>Manage Wallet</title>
          <meta
            name="description"
            content="BounceAd Wallet, manage your wallet"
          />
          <link rel="canonical" href="/payment" />
        </Helmet>
        <Wallets />
      </>
    ),
  },
  {
    path: '/settings',
    element: (
      <>
        {' '}
        <Helmet>
          <title>Settings</title>
          <meta name="description" content="BounceAd settings page" />
          <link rel="canonical" href="/settings" />
        </Helmet>
        <Settings />
      </>
    ),
  },
  {
    path: '/tnc',
    element: (
      <>
        {' '}
        <Helmet>
          <title>Terms & Conditions</title>
          <meta name="description" content="BounceAd terms and conditions" />
          <link rel="canonical" href="/tnc" />
        </Helmet>
        <TermsAndConditions />
      </>
    ),
  },
  {
    path: '/privacy-policy',
    element: (
      <>
        {' '}
        <Helmet>
          <title>Privacy Policy</title>
          <meta name="description" content="Privacy Policy of BounceAd" />
          <link rel="canonical" href="/privacy-policy" />
        </Helmet>
        <PrivacyPolicy />
      </>
    ),
  },
]);

// console.log('REACT_APP_BASE_URL', process.env.REACT_APP_BASE_URL);

function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
