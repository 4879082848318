import { Link } from 'react-router-dom';

const CampaignList = ({ packageData }) => {
  return (
    <div className="rounded-3xl border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
      <div className="max-w-full overflow-x-auto">
        <table className="w-full table-auto">
          <thead>
            <tr className="bg-gray-2 text-left dark:bg-meta-4">
              {/* <th className="max-w-[2px] py-4 px-4 font-bold text-black dark:text-white xl:pl-11">
                Id
              </th> */}
              <th className="min-w-[220px] py-4 px-4 font-bold text-black dark:text-white xl:pl-11">
                Camapaign name
              </th>
              <th className="max-w-[100px] py-4 px-4 font-bold text-black dark:text-white xl:pl-11">
                Camapaign Type
              </th>
              <th className="min-w-[220px] py-4 px-4 font-bold text-black dark:text-white xl:pl-11">
                Ad Format
              </th>
              <th className="min-w-[220px] py-4 px-4 font-bold text-black dark:text-white xl:pl-11">
                Start & End Date
              </th>
              <th className="max-w-[50px] py-4 px-4 font-bold text-black dark:text-white">
                Status
              </th>
              <th className="py-4 px-4 font-bold text-black dark:text-white">
                Actions
              </th>
            </tr>
          </thead>

          {packageData.length > 0 && (
            <tbody>
              {packageData.map((packageItem, index) => (
                <tr
                  key={packageItem.id}
                  className={`${index % 2 == 0 ? 'bg-slate-100  rounded-full' : ''}`}
                >
                  {/* <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                    <h5 className="font-medium text-success dark:text-white">
                      {packageItem.id}
                    </h5>
                  </td> */}
                  <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                    <h5 className="font-medium text-success dark:text-white">
                      {packageItem.name}
                    </h5>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                    <h5 className="font-medium text-black dark:text-white">
                      {packageItem?.campaign_type?.value}
                    </h5>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                    <h5 className="font-medium text-black dark:text-white">
                      {packageItem?.ad_format?.value}
                    </h5>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <p className="text-black dark:text-white">
                      {packageItem.start_date}{' '}
                      <b className="text-red-600">- - -</b>{' '}
                      {packageItem.end_date}
                    </p>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <p
                      className={`inline-flex rounded-full bg-opacity-10 py-1 px-3 text-sm font-medium ${
                        packageItem.status.trim().toLowerCase() === 'draft'
                          ? 'bg-warning text-warning'
                          : packageItem.status.trim().toLowerCase() === 'active'
                            ? 'bg-primary text-primary'
                            : packageItem.status.trim().toLowerCase() ===
                                'completed'
                              ? 'bg-success text-success'
                              : 'bg-danger text-danger'
                      }`}
                    >
                      {packageItem.status
                        .trim()
                        .toLowerCase()
                        .charAt(0)
                        .toUpperCase() +
                        packageItem.status.trim().toLowerCase().slice(1)}
                    </p>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    {/* {packageItem.status.trim().toLowerCase() != 'draft' ? ( */}
                    <Link
                      to={`/campaign/Edit/${packageItem.id}/${packageItem.name}`}
                      state={{ id: packageItem.id, name: packageItem.name }}
                      className="hover:text-primary flex flex-row  space-x-2"
                    >
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z"
                        />
                      </svg>
                      <p>Edit</p>
                    </Link>
                    {/* ) : ( */}
                    {/* <Link
                        to={`/payment`}
                        state={{ id: packageItem.id }}
                        className="text-success hover:font-semibold flex flex-row space-x-0.5 justify-center items-center bg-success bg-opacity-20 px-1 rounded-lg flex-wrap"
                      >
                        <svg
                          className="w-6 h-6 text-success dark:text-white self-center"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4 5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H4Zm0 6h16v6H4v-6Z"
                            clipRule="evenodd"
                          />
                          <path
                            fillRule="evenodd"
                            d="M5 14a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1Zm5 0a1 1 0 0 1 1-1h5a1 1 0 1 1 0 2h-5a1 1 0 0 1-1-1Z"
                            clipRule="evenodd"
                          />
                        </svg>

                        <p>Pay Now</p>
                      </Link> */}
                    {/* )} */}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default CampaignList;
