import { useNavigate } from 'react-router-dom';
import useApiCalls from './APICalls';

export function useUploadFile() {
  const navigate = useNavigate();
  const apiCalls = useApiCalls(); // Moved inside the hook

  const uploadFile = async (file_obj, setUploadProgressStatus = null) => {
    console.log('file_obj', file_obj);
    try {
      const formData = new FormData();
      formData.append('file', file_obj);
      const response = await apiCalls(
        'api/fileupload',
        'POST',
        formData,
        {},
        process.env.REACT_APP_BASE_URL,
        {},
        false,
        {
          onUploadProgress: function (progressEvent) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );
            setUploadProgressStatus &&
              setUploadProgressStatus(percentCompleted);
          },
        },
      );
      console.log('responsefileupload', response.data);
      return response;
    } catch (error) {
      console.error('Upload file error:', error);
      navigate('/Error/page/505');
    }
  };

  return uploadFile;
}

export function useGetSignedUrl() {
  const navigate = useNavigate();
  const apiCalls = useApiCalls();

  const getSignedUrl = async (file_paths) => {
    console.log('file_paths', file_paths);

    if (file_paths.length > 0 && file_paths[0].includes('.s3.amazonaws.com/')) {
      try {
        const formData = { file_paths };

        const response = await apiCalls('api/signed_url', 'POST', formData);
        return response.data.data.signed_file_paths;
      } catch (error) {
        console.error('Get signed URL error:', error);
        navigate('/Error/page/505');
      }
    } else {
      return file_paths;
    }
  };

  return getSignedUrl;
}
