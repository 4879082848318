import React from 'react';
import './loader.css';

const Loader = ({ text = 'Loading .... ' }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-transparent bg-opacity-0 z-999 flex-col">
      <div className="absolute inset-0 bg-blue-400 bg-opacity-20 pointer-events-none"></div>
      <div className="loader">
        <span className="p-1"></span>
      </div>
      <h1 className="mt-3 text-xl font-semibold text-slate-500">{text}</h1>
    </div>
  );
};

export { Loader };
