import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="flex justify-center h-screen lg:mt-10">
      <div className="flex flex-col space-y-10 p-5 lg:p-0 max-w-5xl w-full">
        <h1 className="text-start" style={{ fontSize: 50, fontWeight: 700 }}>
          Terms and Conditions
        </h1>
        <div className="text-left space-y-4">
          <p>
            Last Updated: 30-Sep.-2024 Welcome to BounceAd, a service provided
            by Qorvex Technologies Private Limited, registered in Delhi, India.
            These Terms and Conditions ("Terms") govern your access to and use
            of our services, including but not limited to advertising on cabs,
            digital displays, print media, and various other advertising
            platforms. By accessing or using our services, you agree to be bound
            by these Terms. Please read them carefully, as they outline the
            legal framework under which our services are provided.
          </p>
          <h2 className="self-start text-3xl font-semibold my-5">
            1. Definitions
          </h2>
          <p>
            To ensure clarity, the following definitions apply throughout these
            Terms: Advertiser:
            <br /> <b>Advertiser: </b>Any individual, company, or entity that
            registers with BounceAd to use our advertising services for
            promoting their products, services, or content.
            <br /> <b>Driver:</b> Any cab driver or individual responsible for
            displaying advertisements via mediums provided by BounceAd,
            including but not limited to digital screens, printed materials, and
            other forms of media.
            <br /> <b>Partner:</b> Any person or entity (including Drivers) who
            collaborates with BounceAd to display advertisements, including
            those who offer their private or commercial spaces, such as building
            facades, outer walls, or vehicles, for ad placement.
            <br /> <b>Content:</b> Any advertising material, including but not
            limited to videos, images, text, graphics, or audio content uploaded
            by Advertisers for display through BounceAd’s services.
            <br /> <b>Service:</b> The range of advertising solutions provided
            by BounceAd, including but not limited to print ads on cabs, digital
            displays inside cabs, outdoor advertising on private properties, and
            any future advertising modules.
            <br /> <b>Platform:</b> The BounceAd app, website, or any other
            digital platform used to facilitate the services.
            <br /> <b>Wallet:</b> A digital account associated with an
            Advertiser’s profile, used for managing funds, transactions, and
            payments related to advertising services.
            <br /> <b>Equipment:</b> Any hardware, digital screens, or devices
            provided or sold by BounceAd to Drivers, Partners, or Advertisers
            for the purpose of displaying advertisements.
          </p>
          <h2 className="self-start text-3xl font-semibold my-5">
            2. Eligibility
          </h2>
          <p>
            <b></b>To use our services, you must meet the following criteria:
          </p>
          <p>
            You must be at least 18 years old and have the legal capacity to
            enter into a binding contract under the laws of India.
          </p>
          <p>
            Organizations or entities must ensure that the individual accepting
            these Terms on their behalf has the authority to bind the entity to
            these Terms.
          </p>
          <p>
            By using our services, you represent and warrant that you meet these
            eligibility requirements.
          </p>
          <br />
          <h2 className="self-start text-3xl font-semibold my-5">
            3. Scope of Services Provided
          </h2>
          <p>
            BounceAd offers a wide range of advertising solutions to meet the
            diverse needs of Advertisers. These services are not limited to but
            include the following:
          </p>
          <br />
          <p>
            <b>3.1 Advertising on Cabs</b>
          </p>
          <br />
          <p>
            Exterior Cab Ads: This involves placing printed advertisements on
            the exterior of cabs, which are visible to the public as the cabs
            move around various locations.
            <br />
            Interior Cab Ads: Advertisements displayed inside cabs through
            digital screens installed on the headrests of passenger seats,
            allowing for targeted engagement with passengers.
            <br />
            Print Ads Inside Cabs: Placement of printed advertisements within
            the interior space of cabs, such as on seatbacks, windows, or other
            suitable surfaces.
          </p>
          <p>
            <b>3.2 Digital and Print Advertising on Private Properties</b>
            <br />
          </p>
          <p>
            Building Facades and Outer Walls: BounceAd collaborates with
            property owners who offer their outer walls, building facades, or
            other exterior spaces for displaying large-format print or digital
            advertisements.
          </p>
          <p>
            Residential and Commercial Spaces: Our services extend to
            advertising placements on private residential or commercial
            properties where owners have agreed to display ads.
          </p>
          <p>
            Other Vehicles: In addition to cabs, we facilitate advertising on
            other vehicles such as buses, trucks, and private cars where owners
            have consented to display ads.
          </p>
          <p>
            <b>3.3 Future Services</b>
            <br />
          </p>
          <p>
            Qorvex Technologies Private Limited reserves the right to expand or
            modify its range of services at any time. These Terms will continue
            to apply to all current and future services unless specifically
            updated or replaced by new terms.
          </p>
          <h2 className="self-start text-3xl font-semibold my-5">
            4. Content Submission and Management
          </h2>
          <p>
            <b>4.1 Submission of Content</b>
            <br />
          </p>
          <p>
            Advertiser Responsibility: Advertisers are solely responsible for
            the content they upload, including ensuring that it is accurate,
            lawful, and complies with all applicable regulations. Advertisers
            must not submit content that is defamatory, obscene, infringing, or
            otherwise unlawful.
          </p>
          <p>
            Public Display and Usage: By submitting content, the Advertiser
            grants BounceAd the right to publicly display the content on various
            platforms managed by BounceAd. This includes, but is not limited to,
            displaying ads on cabs, private properties, digital platforms, or
            any other advertising medium provided by BounceAd.
          </p>
          <p>
            Content Review and Approval: All content is subject to review and
            approval by BounceAd before it is displayed. BounceAd reserves the
            right to reject any content that does not comply with its standards
            or applicable laws.
          </p>
          <p>
            <b>4.2 Rights to Use Advertiser Content</b>
            <br />
          </p>
          <p>
            Promotional Use: Qorvex Technologies Private Limited reserves the
            right to use the Advertiser’s name, logo, and submitted content for
            promotional and marketing purposes. This may include showcasing
            successful campaigns or highlighting our services.
          </p>
          <p>
            Third-Party Service Providers: We may collaborate with third-party
            service providers to deliver advertising content. Advertisers
            consent to this collaboration and acknowledge that content
            management may involve external parties.
          </p>

          <p>
            <b>4.3 Content Liability and Compliance</b>
            <br />
          </p>
          <p>
            Advertiser Liability: Advertisers are liable for all content shown
            through BounceAd services. Qorvex Technologies Private Limited is
            not responsible for any incorrect, misleading, or prohibited content
            displayed.
          </p>
          <p>
            Legal Compliance: Advertisers must ensure that all content complies
            with the laws of India, including local, state, and national
            regulations. This includes obtaining any necessary approvals or
            clearances for the content from relevant authorities.
          </p>
          <p>
            Prohibited Content: Content that promotes hate speech, violence,
            illegal activities, or any material deemed inappropriate by Qorvex
            Technologies Private Limited is strictly prohibited. BounceAd
            reserves the right to remove such content immediately and take
            appropriate actions, including legal recourse if necessary.
          </p>

          <h2 className="self-start text-3xl font-semibold my-5">
            5. Payments, Wallet Management, and Refunds
          </h2>
          <p>
            <b>5.1 Wallet System</b>
            <br />
          </p>
          <p>
            Funding the Wallet: Advertisers can add funds to their Wallet via
            the BounceAd app using various payment methods, including but not
            limited to credit/debit cards, online banking, and digital wallets.
            Once a payment is made, the corresponding amount is credited to the
            Advertiser’s Wallet.
          </p>
          <p>
            Usage and Deductions: Funds in the Wallet are used to pay for
            advertising services. Deductions occur as advertisements are
            displayed based on metrics such as impressions, clicks, duration of
            display, or other criteria defined by the service agreement.
          </p>
          <p>
            Account Balance and Statements: Advertisers can view their Wallet
            balance and transaction history at any time through the BounceAd
            app. It is the responsibility of the Advertiser to monitor their
            account and ensure sufficient funds are available for ongoing
            campaigns.
          </p>
          <p>
            <b>5.2 Refunds and Account Closure</b>
            <br />
          </p>
          <p>
            Refund Policy: While we strive to refund any remaining balance in
            the Advertiser’s Wallet upon service termination or account closure,
            Qorvex Technologies Private Limited does not guarantee refunds of
            unused funds. Refunds will be processed at our sole discretion, and
            specific terms may apply depending on the circumstances of the
            termination.
          </p>
          <p>
            Non-Transferable Funds: Funds in the Wallet are non-transferable and
            cannot be moved to other accounts or third parties.
          </p>
          <p>
            <b>5.3 Payment Disputes and Resolution</b>
            <br />
          </p>
          <p>
            Dispute Handling: Any disputes regarding payments, Wallet balances,
            or deductions should be reported to BounceAd’s customer support
            within 30 days of the disputed transaction. BounceAd will
            investigate and resolve disputes in accordance with its policies.
          </p>
          <p>
            Chargebacks: In the event of a chargeback or payment dispute
            initiated by the Advertiser, BounceAd reserves the right to suspend
            or terminate the Advertiser’s account and take appropriate legal
            actions to recover any owed amounts.
          </p>

          <h2 className="self-start text-3xl font-semibold my-5">
            6. Obligations of Advertisers, Drivers, and Partners
          </h2>
          <p>
            <b>6.1 Advertiser Obligations</b>
            <br />
          </p>
          <p>
            Content Accuracy: Advertisers must ensure that all information
            provided in their content is accurate and up-to-date. Any misleading
            or false information can lead to immediate suspension of services.
          </p>
          <p>
            Compliance with Display Requirements: Advertisers must comply with
            BounceAd’s guidelines on content formats, sizes, and display
            specifications. Non-compliant content may be rejected or require
            modifications.
          </p>
          <p>
            <b>6.2 Driver and Partner Obligations</b>
            <br />
          </p>
          <p>
            Ad Display Requirements: Drivers and Partners are required to
            display advertisements according to the schedules, locations, and
            durations agreed upon with BounceAd. Failure to adhere to these
            requirements may result in payment deductions, withholding of
            payments, or termination of the agreement.
          </p>
          <p>
            Equipment Maintenance: Drivers and Partners are responsible for the
            proper use and maintenance of equipment provided by BounceAd. This
            includes ensuring that digital screens, printed materials, and other
            display devices are operational and presenting ads correctly.
          </p>
          <p>
            Reporting Issues: Drivers and Partners must report any issues with
            equipment or ad displays to BounceAd immediately. Prompt reporting
            ensures that any problems can be addressed quickly to minimize
            disruptions in service.
          </p>
          <p>
            <b>6.3 Use of Private Properties for Advertising</b>
            <br />
          </p>
          <p>
            Property Owner Obligations: Property owners who agree to display ads
            on their buildings or other spaces must ensure that the ads are
            visible and maintained according to the terms of their agreement
            with BounceAd. Owners are also responsible for complying with any
            local regulations regarding outdoor advertising.
          </p>
          <p>
            Liability for Damages: BounceAd is not liable for any damages to
            private properties caused by the installation, display, or removal
            of advertisements. Property owners must ensure that installations
            are conducted safely and in compliance with any applicable building
            codes.
          </p>
          <h2 className="self-start text-3xl font-semibold my-5">
            7. Equipment and Warranty Disclaimer
          </h2>
          <p>
            <b>7.1 Provision and Use of Equipment</b>
            <br />
          </p>
          <p>
            Equipment Sales and Rentals: BounceAd may provide or sell equipment
            such as digital screens, mounts, or other devices to Drivers,
            Partners, or Advertisers. The terms of use for such equipment will
            be outlined in separate agreements or contracts.
          </p>
          <p>
            No Warranty: Qorvex Technologies Private Limited does not provide
            warranties for any equipment or devices sold or rented. Any
            warranties are limited to those offered by the original
            manufacturer, if applicable.
          </p>
          <p>
            <b>7.2 Liability and Maintenance</b>
            <br />
          </p>
          <p>
            Liability: Qorvex Technologies Private Limited is not liable for any
            damages, malfunctions, or failures of equipment provided, whether
            purchased directly from Qorvex Technologies Private Limited or
            through third parties. Users are responsible for ensuring that
            equipment is used correctly and maintained as per the manufacturer’s
            guidelines.
          </p>
          <p>
            Maintenance and Repairs: Any costs associated with the maintenance,
            repair, or replacement of equipment are the responsibility of the
            Drivers, Partners, or Advertisers. BounceAd may offer repair
            services at its discretion, subject to availability and at an
            additional cost.
          </p>
          <p>
            <b>6.3 Use of Private Properties for Advertising</b>
            <br />
          </p>
          <h2 className="self-start text-3xl font-semibold my-5">
            8. Service Modifications, Suspension, and Termination
          </h2>
          <p>
            <b>8.1 Right to Modify Services</b>
            <br />
          </p>
          <p>
            Modifications: Qorvex Technologies Private Limited reserves the
            right to modify, suspend, or discontinue any part of its services at
            any time without prior notice. This includes changing the types of
            advertising offered, altering pricing models, or updating service
            features.
          </p>
          <p>
            No Liability for Modifications: BounceAd shall not be liable for any
            consequences arising from service modifications, including loss of
            access to certain features or changes in service levels.
          </p>

          <p>
            <b>8.2 Service Suspension and Termination</b>
            <br />
          </p>
          <p>
            Grounds for Suspension: BounceAd may suspend or terminate your
            access to the services if you violate these Terms, fail to pay
            required fees, or engage in any conduct that is harmful to BounceAd,
            its users, or its partners.
          </p>
          <p>
            Effect of Termination: Upon termination, all licenses and rights
            granted to you under these Terms will cease immediately. You must
            cease all use of the services, and any outstanding balances in your
            Wallet may be forfeited.
          </p>

          <h2 className="self-start text-3xl font-semibold my-5">
            9. Indemnification
          </h2>
          <p>
            <b>9.1 Advertiser Indemnification</b>
            <br />
          </p>
          <p>
            Indemnity Obligations: Advertisers agree to indemnify and hold
            harmless Qorvex Technologies Private Limited, its affiliates,
            officers, agents, and employees from any claims, damages,
            liabilities, costs, or expenses arising from: The content they
            provide. Their use of the services. Any breach of these Terms.
          </p>
          <p>
            <b>9.2 Driver and Partner Indemnification</b>
            <br />
          </p>
          <p>
            Indemnity Obligations: Drivers and Partners agree to indemnify and
            hold harmless Qorvex Technologies Private Limited from any claims,
            damages, liabilities, costs, or expenses arising from their
            obligations and actions related to the display of advertisements.
          </p>
          <h2 className="self-start text-3xl font-semibold my-5">
            10. Limitation of Liability
          </h2>
          <p>
            <b>10.1 Exclusion of Liability</b>
            <br />
          </p>
          <p>
            Content Liability: Qorvex Technologies Private Limited shall not be
            liable for any content displayed through its services, including
            errors, omissions, or inaccuracies in the content.
          </p>
          <p>
            Service Interruptions: The company shall not be liable for any
            interruptions, delays, or errors in service, including those caused
            by technical issues, maintenance, updates, or external factors
            beyond the control of BounceAd.
          </p>
          <p>
            <b>10.2 Limitation of Damages</b>
            <br />
          </p>
          <p>
            Indirect Damages: To the fullest extent permitted by law, Qorvex
            Technologies Private Limited shall not be liable for any indirect,
            incidental, consequential, special, or punitive damages arising from
            or related to these Terms or the use of BounceAd services.
          </p>
          <h2 className="self-start text-3xl font-semibold my-5">
            11. Data Privacy and Security
          </h2>
          <p>
            <b>11.1 Data Collection and Use</b>
            <br />
          </p>
          <p>
            Consent to Data Collection: By using our services, you consent to
            the collection, use, and sharing of your data in accordance with our
            Privacy Policy, which outlines how we handle personal information.
          </p>
          <p>
            Usage of Data: Data collected may be used for purposes including but
            not limited to service provision, improvements, marketing, and
            compliance with legal obligations.
          </p>
          <p>
            <b>11.2 Security Measures</b>
            <br />
          </p>
          <p>
            Data Security: BounceAd implements reasonable security measures to
            protect your data, including encryption, secure servers, and access
            controls. However, no system is completely secure, and we cannot
            guarantee the absolute security of your information and
            transactions.
          </p>

          <h2 className="self-start text-3xl font-semibold my-5">
            12. Intellectual Property Rights
          </h2>
          <p>
            <b>12.1 Ownership of Intellectual Property</b>
            <br />
          </p>
          <p>
            Service Content: All intellectual property rights related to the
            services, including software, content, trademarks, and branding, are
            owned by Qorvex Technologies Private Limited or its licensors.
            Unauthorized use of these intellectual properties is strictly
            prohibited.
          </p>
          <p>
            <b>12.2 License to Use Content</b>
            <br />
          </p>
          <p>
            License Grant: By submitting content for advertising purposes,
            Advertisers grant Qorvex Technologies Private Limited a
            non-exclusive, royalty-free, worldwide license to use, reproduce,
            and display the content in connection with the provision of
            advertising services.
          </p>
          <h2 className="self-start text-3xl font-semibold my-5">
            13. Governing Law and Dispute Resolution
          </h2>
          <p>
            <b>13.1 Governing Law</b>
            <br />
          </p>
          <p>
            Jurisdiction: These Terms shall be governed by and construed in
            accordance with the laws of India, without regard to its conflict of
            law principles. All users agree to submit to the jurisdiction of the
            courts located in Delhi, India.
          </p>
          <p>
            <b>13.2 Dispute Resolution</b>
            <br />
          </p>
          <p>
            Arbitration: Any disputes arising out of or in connection with these
            Terms shall be resolved through arbitration in Delhi, India, in
            accordance with the Indian Arbitration and Conciliation Act, 1996.
            The decision of the arbitrator shall be final and binding on the
            parties.
          </p>
          <h2 className="self-start text-3xl font-semibold my-5">
            14. Amendments to Terms
          </h2>
          <p>
            Right to Amend: Qorvex Technologies Private Limited reserves the
            right to amend these Terms at any time. Any changes will be posted
            on the BounceAd platform, and continued use of the services
            constitutes acceptance of the revised Terms. It is the user’s
            responsibility to review these Terms periodically for updates.
          </p>
          <h2 className="self-start text-3xl font-semibold my-5">
            15. Miscellaneous
          </h2>
          <p>
            <b>15.1 Entire Agreement</b>
            <br />
          </p>
          <p>
            Full Agreement: These Terms constitute the entire agreement between
            you and Qorvex Technologies Private Limited regarding the use of
            BounceAd services and supersede any prior agreements or
            understandings, whether written or oral.
          </p>
          <p>
            <b>15.2 Severability</b>
            <br />
          </p>
          <p>
            Partial Invalidity: If any provision of these Terms is found to be
            invalid or unenforceable, the remaining provisions shall remain in
            full force and effect, and the invalid provision shall be replaced
            by a valid provision that most closely reflects the original intent.
          </p>
          <p>
            <b>15.3 Waiver</b>
            <br />
          </p>
          <p>
            Non-Waiver: The failure of Qorvex Technologies Private Limited to
            enforce any right or provision of these Terms shall not constitute a
            waiver of such right or provision unless acknowledged and agreed to
            by us in writing.
          </p>
          <p>
            <b>15.4 Assignment</b>
            <br />
          </p>
          <p>
            Non-Transferable Rights: You may not assign or transfer your rights
            or obligations under these Terms without the prior written consent
            of Qorvex Technologies Private Limited. We may assign our rights and
            obligations under these Terms to any third party without notice.
          </p>
          <p>
            <b>15.5 Force Majeure</b>
            <br />
          </p>
          <p>
            No Liability for Force Majeure Events: Qorvex Technologies Private
            Limited shall not be liable for any failure or delay in performance
            due to causes beyond its reasonable control, including acts of God,
            war, terrorism, labor disputes, natural disasters, or interruptions
            in services or utilities.
          </p>
          <p>
            <b>15.6 Contact Information</b>
            <br />
          </p>
          <p>
            Customer Support: For any questions, concerns, or disputes regarding
            these Terms or the services provided by BounceAd, please contact us
            at support@bouncead.com
          </p>
          <h2 className="self-start text-3xl font-semibold my-5">
            16. Refund Policy
          </h2>
          <p>
            Non-Refundable Amounts: Funds added to the Wallet are generally
            non-refundable. Refunds may only be considered if there is no
            currently running advertising campaign linked to the Wallet at the
            time of the refund request.
          </p>
          <p>
            Refunds of Remaining Balance: In cases where a refund is applicable,
            only the remaining balance in the Wallet after deducting the
            platform fees will be refunded. The platform fee is 10% of the total
            amount initially added to the Wallet.
          </p>
          <p>
            Refund Request Process: Refund requests must be submitted through
            the designated support channels on the BounceAd platform, and will
            be subject to review and approval by Qorvex Technologies Private
            Limited.
          </p>
          <h2 className="self-start text-3xl font-semibold my-5">
            17. Use of Collected Data
          </h2>
          <p>
            Data Utilization for Service Improvement: Qorvex Technologies
            Private Limited may collect data from various sources, including but
            not limited to the BounceAd web app, driver app, and other
            associated platforms. This data may include user interactions,
            preferences, and performance metrics.
          </p>
          <p>
            Purpose of Data Use: The data collected will be used to improve our
            services, develop new products, enhance user experience, and
            optimize advertising solutions. By using our services, you consent
            to the collection and use of your data for these purposes.
          </p>
          <p>
            Data Privacy: The use of collected data will comply with applicable
            privacy laws and regulations, and our Privacy Policy outlines the
            details of data handling practices.
          </p>
          <h1 className='my-24'></h1>
          <h2 className='flex justify-center items-center'>....................... END .....................</h2>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
