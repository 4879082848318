import React, { useEffect, useState } from 'react';
import MultiSelect from '../../common/MultiSelect';
// import useApiCalls from '../../utility/APICalls';

const Location = ({
  setLocation,
  current_status = 'fresh',
  current_data = [],
}) => {
  const editable = ['fresh', 'draft'].includes(
    current_status?.trim().toLowerCase(),
  );

  const [selectedLocations, setSelectedLocations] = useState([]);
  useEffect(() => {
    console.log('selectedLocations', selectedLocations);
    var selectedValues = Object.values(selectedLocations).filter(
      (item) => item.selected === true,
    );
    setLocation(selectedValues);
  }, [selectedLocations]);

  return (
    <MultiSelect
      title="Select Locations"
      callBackFunction={setSelectedLocations}
      endpoint="api/location"
      editable={editable}
      current_data={current_data}
    />
  );
};

export default Location;
