function parseDateStr(input) {
  const parts = input.split('/');
  return new Date(parts[2], parts[1] - 1, parts[0]);
}

function daysBetween(date1, date2) {
  const startDate = new Date(date1);
  const endDate = new Date(date2);
  const timeDifference = Math.abs(endDate - startDate);
  const dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  return dayDifference;
}

export { parseDateStr, daysBetween };
