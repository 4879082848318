import React, { useCallback, useEffect, useState } from 'react';
import { Loader } from '../../../common/Loader';
import DragNdrop from '../../../common/draganddrop/DragNdrop';
import useApiCalls from '../../../utility/API/APICalls';
import CarCover from './Adtypes/CarCover';
import SeatBackLaminated from './Adtypes/SeatBackLaminated';
import VideoAd from './Adtypes/VideoAd';
import ImpressionValue from './ImpressionValue';

const YourAd = ({
  showSkip,
  fileUrl,
  setUploadedFile,
  campaignName,
  setFileSizeFile,
  budgeError = '',
  cancelForm,
  submitForm,
  adFormat,
  setDurationInSeconds,
  setVideoDurationError,
  current_status = 'fresh',
  current_data = '',
}) => {
  const [durationError, setDurationError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [carCoversSizes, setCarCoversSizes] = useState([]);

  useEffect(() => {
    // console.log('durationError',durationError)
    if (durationError) {
      setVideoDurationError(true);
    } else {
      setVideoDurationError(false);
    }
  }, [durationError]);

  const submitform = (e) => {
    submitForm(e);
  };
  const uploadFile = useCallback(
    (type, url) => {
      setFileSizeFile(type, url);
    },
    [setFileSizeFile],
  );

  useEffect(() => {
    console.log('first_fileurl', fileUrl);
  }, [fileUrl]);

  const apiCalls = useApiCalls();

  const fetchCarCovers = async () => {
    const response = await apiCalls('api/car_model', 'GET');
    if (response && response.status === 200) {
      console.log('response.data', response.data.data);
      setCarCoversSizes(response.data.data);
    }
  };

  useEffect(() => {
    if (adFormat === 'Outside Cab Print Ads' && carCoversSizes.length <= 0) {
      setIsLoading(true);
      fetchCarCovers();
      setIsLoading(false);
    }
  }, [adFormat]);

  return (
    <>
      {isLoading && <Loader />}
      <div className="flex-1 xl:flex-none xl:w-1/3">
        <div className="border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark rounded-3xl">
          <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
            <h3 className="font-medium text-black dark:text-white">
              Your Advertisement
            </h3>
          </div>
          <div className="flex flex-col items-center justify-center w-full max-w-sm mx-auto relative p-1">
            {showSkip && (
              <div className="absolute right-1 top-55 bg-slate-500 z-99 bg-opacity-30 w-20 h-12 flex justify-center items-center rounded-l-lg text-white">
                Skip Ad
              </div>
            )}
            {/* {adFormat && <h1 className='text-success bg-success bg-opacity-10 px-2 rounded-md my-2'>This is how your ad will look</h1>} */}
            {adFormat && (
              <h1 className="text-success bg-success bg-opacity-10 px-2 rounded-md my-2">
                {adFormat === 'Inside Cab Video Ads'
                  ? 'This is how your ad will play'
                  : 'Example Ad'}
              </h1>
            )}

            {/*  video ad */}
            {adFormat === 'Inside Cab Video Ads' && (
              <VideoAd
                fileUrl={fileUrl}
                setDurationInSeconds={setDurationInSeconds}
                setVideoDurationError={setDurationError}
              />
            )}
            {adFormat === 'Seat Back Laminated Branding' && (
              <SeatBackLaminated fileUrl={fileUrl} />
            )}
            {adFormat === 'Outside Cab Print Ads' && (
              <CarCover fileUrl={fileUrl} />
            )}
            {/* laminated seat back ad */}

            {/* Outer seat cover ad */}

            {adFormat === 'Outside Cab Print Ads' ? (
              <div className="flex flex-wrap bg-white space-x-1 space-y-1 mt-5 max-h-50 overflow-y-scroll border rounded-lg border-slate-300 p-2">
                {carCoversSizes &&
                  carCoversSizes.map((item, index) => (
                    <DragNdrop
                      onFilesSelected={uploadFile}
                      adFormat={adFormat}
                      download_file_url={item.image_url}
                      formattedAd={true}
                      title={item.value}
                      // title="Indica Creative"
                      current_status={current_status}
                      current_data={current_data}
                    />
                  ))}
                {/* <DragNdrop
                  onFilesSelected={uploadFile}
                  adFormat={adFormat}
                  download_file_url="https://picsum.photos/200/300"
                  formattedAd={true}
                  title="Swift D-zire Creative"
                  current_status={current_status}
                  current_data={current_data}
                />
                <DragNdrop
                  onFilesSelected={uploadFile}
                  adFormat={adFormat}
                  download_file_url="https://picsum.photos/200/300"
                  formattedAd={true}
                  title="WagonR Creative"
                  current_status={current_status}
                  current_data={current_data}
                />
                <DragNdrop
                  onFilesSelected={uploadFile}
                  adFormat={adFormat}
                  download_file_url="https://picsum.photos/200/300"
                  formattedAd={true}
                  title="Etios Creative"
                  current_status={current_status}
                  current_data={current_data}
                />
                <DragNdrop
                  onFilesSelected={uploadFile}
                  adFormat={adFormat}
                  download_file_url="https://picsum.photos/200/300"
                  formattedAd={true}
                  title="Ritz Creative"
                  current_status={current_status}
                  current_data={current_data}
                />
                <DragNdrop
                  onFilesSelected={uploadFile}
                  adFormat={adFormat}
                  download_file_url="https://picsum.photos/200/300"
                  formattedAd={true}
                  title="Xcent Creative"
                  current_status={current_status}
                  current_data={current_data}
                /> */}
              </div>
            ) : (
              <div className="overflow-hidden bg-white rounded-lg shadow-lg dark:bg-gray-800 justify-center flex flex-col space-y-2 my-5">
                <DragNdrop
                  onFilesSelected={setUploadedFile}
                  adFormat={adFormat}
                  height="5%"
                  current_status={current_status}
                  current_data={current_data}
                />
              </div>
            )}

            <div className="flex flex-wrap bg-white space-x-1 space-y-1 self-center justify-center items-center">
              <h3 className="font-bold tracking-wide self-center text-gray-800 dark:text-white mt-5 text-lg">
                {campaignName}
              </h3>
              <ImpressionValue budgeError={budgeError} />
            </div>

            <div className="flex justify-end m-2 space-x-5">
              {/* {edit ? (
              <button
                className="flex justify-center bg-red-600 rounded border border-stroke py-2 px-6 font-medium text-white hover:shadow-1 dark:border-strokedark"
                onClick={cancelForm}
                type="button"
              >
                Pause Ad
              </button>
            ) : ( */}
              <button
                className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-black hover:shadow-1 dark:border-strokedark dark:text-white"
                onClick={cancelForm}
                type="button"
              >
                Cancel
              </button>
              {/* )} */}

              <button
                className={`flex justify-center rounded bg-opacity-90 bg-blue-600 py-2 px-6 font-bold  text-white hover:bg-blue-800 ${
                  durationError ? 'bg-slate-500' : ''
                }`}
                type="submit"
                onClick={submitform}
                disabled={durationError}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default YourAd;
