import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CheckBox from '../../common/CheckBox';
import InputComponent from '../../common/InputComponent';
import { Loader } from '../../common/Loader';
import useApiCalls from '../../utility/API/APICalls';

const ExternalUrl = ({
  setValueCallback,
  current_status = 'fresh',
  current_data = {},
}) => {
  const [checkBoxData, setCheckBoxData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const apicalls = useApiCalls();
  const callApi = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await apicalls('api/userinteractions', 'GET');
      if (response.status === 200) {
        setCheckBoxData(response.data.data);
      } else {
        setCheckBoxData([]);
      }
    } catch (error) {
      console.log('errorerror', error);
      setCheckBoxData([]);
    } finally {
      setIsLoading(false);
    }
  }, [apicalls]);

  useEffect(() => {
    if (checkBoxData.length <= 0) {
      callApi();
    }
  }, []);

  const [selectedLinkTypeIndex, setSelectedLinkTypeIndex] = useState(null);
  const [text, setText] = useState('');
  const [link, setLink] = useState('');

  useEffect(() => {
    // console.log('text_selectedLinkTypeIndex_link',checkBoxData,checkBoxData[selectedLinkTypeIndex]);
    const data = {
      value:
        selectedLinkTypeIndex !== null
          ? checkBoxData[selectedLinkTypeIndex]
          : undefined,
      text_data: text,
      link: link,
    };
    console.log('text_selectedLinkTypeIndex_linkdata', data);
    setValueCallback(data);
  }, [text, selectedLinkTypeIndex, link, checkBoxData]);

  useEffect(() => {
    console.log('current_datacurrent_data', current_data);
    if (current_data?.link) {
      setSelectedLinkTypeIndex(current_data.value.id - 1);
      setText(current_data.text_data);
      setLink(current_data.link);
    }
  }, [current_data, checkBoxData]);

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const handleCheckboxChange = (index) => {
    setSelectedLinkTypeIndex(index === selectedLinkTypeIndex ? null : index);
  };

  const editable = ['fresh', 'draft'].includes(
    current_status?.trim().toLowerCase(),
  );

  // useEffect(() => {
  //   if (current_data?.value) {
  //     setValueCallback(current_data);
  //   }
  // }, [current_data]);

  const Icon = () => (
    <svg
      className="w-6 h-6 text-gray-800 dark:text-white"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M13.213 9.787a3.391 3.391 0 0 0-4.795 0l-3.425 3.426a3.39 3.39 0 0 0 4.795 4.794l.321-.304m-.321-4.49a3.39 3.39 0 0 0 4.795 0l3.424-3.426a3.39 3.39 0 0 0-4.794-4.795l-1.028.961"
      />
    </svg>
  );
  return isLoading ? (
    <Loader />
  ) : (
    <div className="flex flex-col sm:flex-row w-full">
      <div className="w-full">
        <div className="flex flex-col lg:flex-row justify-center lg:justify-evenly items-start lg:items-center my-5 space-y-2 lg:space-x-1">
          {checkBoxData.map((item, index) => (
            <CheckBox
              key={item.id}
              title={item.value}
              index={index}
              isChecked={index === selectedLinkTypeIndex}
              onCheckboxChange={() => handleCheckboxChange(index)}
              disabled={!editable}
            />
          ))}
        </div>
        <div className="w-full flex items-center flex-col justify-center">
          <InputComponent
            getUpdatedValue={setLink}
            placeholder="https://your_external_url.com"
            Icon={<Icon />}
            editable={editable}
            current_data={current_data?.link}
          />
          <h1 className="self-start text-xs my-2 text-slate-500">
            your link will be converted to QR code and will be shown to the
            viewers at the end of your ad.
          </h1>
          <div className="w-full mt-5">
            <textarea
              value={text}
              onChange={handleChange}
              maxLength={100}
              placeholder="Add details you want to show to yours with your link (max 100 characters)"
              rows={2}
              cols={50}
              disabled={!editable}
              className={`w-full p-2 rounded-md border border-gray-300 text-sm ${!editable ? 'bg-slate-200 text-slate-500' : ''}`}
            />
          </div>
        </div>
        <div className="bg-green-500 bg-opacity-20 px-2 py-2 mb-5 mt-5 rounded-md text-xs opacity-60">
          <h1 className="text-green-900 flex flex-wrap space-x-2 space-y-1">
            you can select any of the above, we'll verify your domain belongs to
            which catagory and auto re-assign the type to it. We want you to
            select the appropriate catagory and provide the correct link.
            providing , invalid url , link to harmful content or any other type
            of content that violates our policy will result in immediate
            suspension of your account. for more information
          </h1>
          <Link
            to="https://google.com"
            className="text-blue-500 text-md"
            target="_blank"
          >
            Advertisement policies
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ExternalUrl;
