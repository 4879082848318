import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
// import Alert from '@mui/material/Alert';

export default function useApiCalls() {
  const navigate = useNavigate();
  const logout = async () => {
    try {
      Cookies.remove('token');
      navigate('/login');
    } catch (error) {
      navigate('/Error/page/505');
    }
  };

  const apiCalls = async (
    endpoint = '',
    method,
    data = null,
    headers = {},
    base_url = process.env.REACT_APP_BASE_URL,
    params = {},
    add_json_header = true,
    configs = {},
  ) => {
    // const apiCalls = async (endpoint = '', method, data = null, headers = {}, base_url = process.env.REACT_APP_BASE_URL, params = {}) => {
    const token = Cookies.get('token');

    if (token) {
      headers = {
        Authorization: token,
        ...headers,
      };
      if (add_json_header) {
        headers['Content-Type'] = 'application/json';
      }
    }
    try {
      let queryParams = '';
      if (params) {
        queryParams = new URLSearchParams(params).toString();
      }

      const config = {
        ...configs,
        method: method,
        headers: headers,
        body: add_json_header
          ? data
            ? JSON.stringify(data)
            : undefined
          : data,
      };

      const endpointUrl = `${base_url}${endpoint.startsWith('/') ? endpoint : '/' + endpoint}${queryParams ? '?' + queryParams : ''}`;
      if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
        console.log(`${method} - ${endpointUrl}`);
        console.log('config', config);
      }

      const response = await fetch(endpointUrl, config);
      const responseData = await response.json();
      // console.log('responseData', responseData);
      if (
        response.status === 500 ||
        response.status === 401 ||
        response.status === 400
      ) {
        if (
          [
            'Unauthorized request',
            'Signature has expired',
            'Session Expired, please re-login',
          ].includes(responseData.msg)
        ) {
          alert(responseData.msg); // Replace with MUI Snackbar or Alert as needed
          await logout();
        }
      }

      return {
        status: response.status,
        data: responseData,
      };
    } catch (error) {
      console.error('API call error:', error);
      navigate('/Error/page/505');
    }
  };

  return apiCalls;
}
