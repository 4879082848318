import useApiCalls from '../../../utility/API/APICalls';

const convertDate = (date) => {
  return date.split('/').reverse().join('-');
};

const checkDataForInCabVideo = async (campaignData) => {
  const age_group = campaignData.demographyDetails?.age.map((item) => item.id);
  const gender = campaignData.demographyDetails?.gender.map((item) => item.id);
  const loc = campaignData.location?.map((item) => item.id);
  const startDate = convertDate(campaignData.budgetAndDates?.startDate);
  const endDate = convertDate(campaignData.budgetAndDates?.endDate);

  const dataset = {
    name: campaignData.campaignName,
    campaign_type: campaignData.adType.id,
    ad_format: campaignData.adFormat.id,
    age_group: age_group,
    gender: gender,
    start_date: startDate,
    end_date: endDate,
    user_interaction: campaignData.userInterectionDetails,
    budget: parseFloat(
      parseFloat(campaignData?.budgetAndDates?.amount).toFixed(2),
    ),
    budget_type: campaignData?.budgetAndDates?.budgetType,
    currency: 'INR',
    locations: loc,
    media_type: 'Video',
    file_url: campaignData?.file_url,
    duration: parseFloat(parseFloat(campaignData?.duration).toFixed(2)),
    bid_amount: parseFloat(parseFloat(campaignData?.bid).toFixed(2)),
  };

  console.log('dataset', dataset);
  return dataset;
};

const checkDataForLaminatedSheets = async (campaignData) => {
  const loc = campaignData.location?.map((item) => item.id);
  const startDate = convertDate(campaignData.budgetAndDates?.startDate);

  const dataset = {
    name: campaignData.campaignName,
    ad_format: campaignData.adFormat.id,
    start_date: startDate,
    currency: 'INR',
    locations: loc,
    media_type: 'Image',
    file_url: campaignData?.file_url,
    number_of_months: parseInt(campaignData?.budgetAndDates?.months),
    total_cabs: parseInt(campaignData?.budgetAndDates?.cabs),
  };

  console.log('dataset', dataset);
  return dataset;
};

export default function useSaveCampaign() {
  const apicalls = useApiCalls();

  const saveData = async (campaignData) => {
    console.log('campaignData', campaignData);
    let fData = null;

    if (campaignData.adFormat.value === 'Inside Cab Video Ads') {
      fData = await checkDataForInCabVideo(campaignData);
    } else if (campaignData.adFormat.value === 'Seat Back Laminated Branding') {
      fData = await checkDataForLaminatedSheets(campaignData);
    }

    if (fData) {
      try {
        const response = await apicalls('api/adcampaign', 'POST', fData);

        if (response.status === 200 || response.status === 201) {
          return false;
        } else {
          const errorData = await response.data;
          console.error('Response Error:', errorData);
          return response.data?.msg;
        }
      } catch (error) {
        return 'Something Bad happend! Please try again later, if still issue persists, please reach out to support team';
      }
    } else {
      return 'Something Bad happend! Please try again later, if still issue persists, please reach out to support team';
    }
  };
  return saveData;
}
