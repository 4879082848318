import React, { useEffect, useState, useCallback } from 'react';
import CheckBox from '../../common/CheckBox';
import { Loader } from '../../common/Loader';
import useApiCalls from '../../utility/API/APICalls';

const AdFormat = ({
  setAdFormat,
  current_status = 'fresh',
  current_data = {},
}) => {
  const [selectedAdFormatIndex, setSelectedAdFormatIndex] = useState(null);
  const [message, setMessage] = useState('');
  const [adFormatInternal, setAdFormatInternal] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [adFormatData, setData] = useState([]);

  const editable = ['fresh', 'draft'].includes(
    current_status?.trim().toLowerCase(),
  );

  useEffect(() => {
    if (current_data?.id) {
      setSelectedAdFormatIndex(current_data.id - 1);
      setData((prev) =>
        prev.map((item, index) => {
          if (index === current_data.id - 1) {
            item['editable'] = true;
          } else {
            item['editable'] = false;
          }
          return item;
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (
      selectedAdFormatIndex !== null &&
      adFormatData.length > selectedAdFormatIndex
    ) {
      const selectedValue = adFormatData[selectedAdFormatIndex];
      setAdFormat(selectedValue);
      setAdFormatInternal(selectedValue);
    }
  }, [selectedAdFormatIndex, setAdFormat, adFormatData]);

  const handleCheckboxChange = (index) => {
    setSelectedAdFormatIndex(index === selectedAdFormatIndex ? null : index);
  };

  const apicalls = useApiCalls();
  const callApi = useCallback(
    async (endpoint) => {
      if (endpoint) {
        setIsLoading(true);
        try {
          const response = await apicalls(endpoint, 'GET');
          if (response.status === 200 && response.data?.data) {
            setData(response.data.data);
          } else {
            setData([]);
          }
        } catch (error) {
          setData([]);
        } finally {
          setIsLoading(false);
        }
      } else {
        setData([]);
      }
    },
    [apicalls],
  );

  useEffect(() => {
    if (adFormatData.length === 0) {
      callApi('api/adformat');
    }
  }, []);

  useEffect(() => {
    if (adFormatInternal?.value) {
      const selectedFormat = adFormatData.find(
        (format) => format.value === adFormatInternal.value,
      );
      if (selectedFormat) {
        setMessage(selectedFormat.description);
      } else {
        setMessage(
          'Oops! This Ad format is not currently enabled. Please select another format.',
        );
      }
    } else {
      setMessage('Please select a valid Ad format.');
    }
  }, [adFormatInternal, adFormatData]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <div className="flex flex-col space-y-2 justify-start items-start">
        {adFormatData.map((item, index) => (
          <CheckBox
            key={index}
            title={item.value}
            index={index}
            isChecked={selectedAdFormatIndex === index}
            onCheckboxChange={() => handleCheckboxChange(index)}
            disabled={
              item.value === 'Other Digital Ad ( coming soon )' || !editable
            }
          />
        ))}
      </div>
      {message && (
        <div className="bg-green-500 bg-opacity-20 px-2 py-2 mb-5 mt-10 rounded-md text-xs opacity-60">
          <h1 className="text-green-900">{message}</h1>
        </div>
      )}
    </>
  );
};

export default AdFormat;
