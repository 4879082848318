import React, { useEffect, useState } from 'react';
import InputComponent from '../../common/InputComponent';
import { Loader } from '../../common/Loader/index';
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb';
import BounceADLogo from '../../images/logo/logo.png';
import DefaultLayout from '../../layout/DefaultLayout';
import useApiCalls from '../../utility/API/APICalls';
import loadScript from '../../utility/LoadScript';

const Wallets = () => {
  const [amount, setAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [balance, setBalance] = useState(0);

  const addMoney = (val) => {
    console.log('addMoneyval', val);
    setAmount((prev) => parseFloat(prev) + parseFloat(val));
  };

  const apiCalls = useApiCalls();

  const addMoneyToWallet = async () => {
    try {
      await displayRazorpay();
      console.log('Amount added to wallet');
    } catch (error) {
      console.log('Error in adding money to wallet', error);
    } finally {
      setIsLoading(false);
    }
  };
  const checkWalletAmount = async () => {
    setIsLoading(true);
    const response = await apiCalls('api/wallet/balance', 'GET');
    console.log(response);
    if (response && response.status === 200) {
      setBalance(response.data.data.balance);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    checkWalletAmount();
  }, []);

  async function displayRazorpay() {
    setIsLoading(true);
    const res = await loadScript(
      'https://checkout.razorpay.com/v1/checkout.js',
    );

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      return;
    }

    // creating a new order

    if (amount <= 0) {
      setErrorMsg('Please enter a valid amount');
      setAmount(0);
      return;
    }

    const data = {
      amount: parseFloat(amount),
    };
    const result = await apiCalls('api/payment/order', 'POST', data);

    if (result && result.status !== 201) {
      setErrorMsg(result.data.message);
      return;
    }

    // Getting the order details back
    // const { amount, id: order_id, currency } = result.data.data;
    const respData = result.data.data;
    console.log('respDatarespData', respData);
    setErrorMsg('');
    const options = {
      key: respData.razorpay_key_id,
      amount: (amount / 100).toString(),
      currency: respData.currency,
      name: respData.receipt,
      description: 'Amount added to wallet',
      image: { BounceADLogo },
      order_id: respData.id,
      // callback_url: '/transactions',
      prefill: {
        name: respData.notes.ENTITY_NAME,
        email: respData.notes.ENTITY_EMAIL,
        contact: respData.notes.ENTITY_PHONE,
      },
      notes: respData.notes,
      theme: {
        color: '#141f9c',
      },
      handler: function (response) {
        console.log('Payment successful:', response);
        window.location.href = '/transactions';
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  return isLoading ? (
    <Loader />
  ) : (
    <DefaultLayout>
      <Breadcrumb pageName="My Wallet" />
      <div className="flex flex-col justify-center items-center mt-10 px-2">
        {/* <h1 className="text-4xl my-5 font-semibold">Wallet</h1> */}
        <div className="flex flex-col justify-center items-evenly h-auto border-2 w-full p-5 lg:w-1/3 rounded-xl border-slate-300 shadow-xl transform duration-500 hover:scale-110">
          <div className="flex justify-between items-center border-b-2  py-2 mb-5">
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="#135c18"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M12 14a3 3 0 0 1 3-3h4a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-4a3 3 0 0 1-3-3Zm3-1a1 1 0 1 0 0 2h4v-2h-4Z"
                clipRule="evenodd"
              />
              <path
                fillRule="evenodd"
                d="M12.293 3.293a1 1 0 0 1 1.414 0L16.414 6h-2.828l-1.293-1.293a1 1 0 0 1 0-1.414ZM12.414 6 9.707 3.293a1 1 0 0 0-1.414 0L5.586 6h6.828ZM4.586 7l-.056.055A2 2 0 0 0 3 9v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2h-4a5 5 0 0 1 0-10h4a2 2 0 0 0-1.53-1.945L17.414 7H4.586Z"
                clipRule="evenodd"
              />
            </svg>

            <h1>
              <b>Balance: </b>₹ {balance}
            </h1>
          </div>
          <div className="flex flex-col justify-start items-start w-full">
            <h1 className="text-md text-slate-800">Add money to wallet</h1>
          </div>
          <div className="my-2">
            <InputComponent
              placeholder="Enter amount"
              Icon="₹"
              getUpdatedValue={setAmount}
              current_data={amount}
            />
          </div>
          <div className="flex flex-row items-center justify-start py-2 space-x-5">
            <button
              className="self-center w-16 h-8 bg-meta-5 hover:scale-110 transform bg-opacity-80 duration-300 shadow-xl rounded-lg text-white hover:bg-opacity-100"
              onClick={() => addMoney(5000)}
            >
              + 5000
            </button>
            <button
              className="self-center w-16 h-8 bg-meta-5 hover:scale-110 transform bg-opacity-80 duration-300 shadow-xl rounded-lg text-white hover:bg-opacity-100"
              onClick={() => addMoney(2000)}
            >
              + 2000
            </button>
            <button
              className="self-center w-16 h-8 bg-meta-5  hover:scale-110 transform bg-opacity-80 duration-300 shadow-xl rounded-lg text-white hover:bg-opacity-100"
              onClick={() => addMoney(1000)}
            >
              + 1000
            </button>
          </div>
          {errorMsg && (
            <h1 className="bg-danger bg-opacity-20 px-2 py-1 my-5 rounded-md text-danger">
              {errorMsg}
            </h1>
          )}
          <button
            className="bg-success bg-opacity-80 text-white h-10 rounded-lg hover:bg-opacity-100 my-2 hover:border-success hover:shadow-xl hover:scale-105 duration-300 transform"
            onClick={addMoneyToWallet}
          >
            Continue
          </button>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Wallets;
