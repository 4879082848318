import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import useApiCalls from '../utility/API/APICalls';
import { useGetSignedUrl } from '../utility/API/FileApis';

const useFetchUserDetails = () => {
  const [data, setData] = useState(null); // Initialize as null
  const [loading, setLoading] = useState(true); // Set initial loading state
  const apiCalls = useApiCalls();
  const getSignedUrl = useGetSignedUrl();
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await apiCalls('api/userprofile', 'GET');
        console.log('responseuser', response);
        if (response && response.status === 200) {
          const userData = response.data.data;
          const signedResponse = await getSignedUrl([userData.profile_pic]);
          userData.profile_pic = signedResponse[0];
          setData(userData);
          Cookies.set('user', JSON.stringify(response.data.data));
        } else {
          console.error('Failed to fetch user data', response);
        }
      } catch (error) {
        console.error('Error fetching user data', error);
      } finally {
        setLoading(false);
      }
    };

    if (!data) {
      fetchUserDetails(); // Only fetch if data is null
    }
  }, [apiCalls, data]); // Dependencies to avoid unnecessary re-fetching

  return { userData: data, loading };
};

export default useFetchUserDetails;
